//
// COLORS
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// primaries
//-----------------------------------------------------------
$yellow-lt: 251, 234, 153; // #fbea99;
$yellow-accent: 255, 200, 0; // #ffc908;
$yellow-dk: 160, 90, 0; // #996600;
$yellow-blk: 90, 30, 0; // #4d3300;

$green-lt: 201, 240, 194; // #c9f0c2;
$green-accent: 87, 218, 76; // #57da4c;
$green-dk: 0, 102, 17; // #006611;
$green-blk: 0, 45, 5; // #003309;

$blue-lt: 205, 230, 254; // #cde6fe;
$blue-accent: 87, 157, 255; // #579dff;
$blue-dk: 11, 75, 203; // #0b4bcb;
$blue-blk: 0, 29, 86; // #001d56;

$pink-lt: 246, 205, 254; // #f6cdfe;
$pink-accent: 255, 110, 206; // #ff6ece;
$pink-dk: 150, 9, 170; // #aa09aa;
$pink-blk: 80, 0, 90; // #5a005a;

$red-lt: 254, 209, 205; // #fed1cd;
$red-accent: 255, 100, 100; // #ff6464;
$red-dk: 153, 13, 0; // #990d00;
$red-blk: 80, 7, 0; // #4d0700;

$grey-lt: 221, 224, 227; // #dde0e3;
$grey-accent: 151, 160, 170; // #97A0AA;
$grey-dk: 43, 46, 59; // #2b2e3b;
$grey-blk: 18, 19, 26; // #12131a;

$mono-lt: 245, 245, 245; // #f5f5f5;
$mono-accent: 133, 133, 133; // #808080;
$mono-dk: 0, 0, 0; // #000000;
$mono-blk: 30, 30, 30; // #333333;

//
// SET CSS VARIABLES
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@include cssvar-set(
  (
    text: '',
    bg: '',
    accent: '',
    blk: '',

    yellow-accent: #{$yellow-accent},
    green-accent: #{$green-accent},
    blue-accent: #{$blue-accent},
    pink-accent: #{$pink-accent},
    red-accent: #{$red-accent},
    grey-accent: #{$grey-accent},
    mono-accent: #{$mono-accent},

    yellow-blk: #{$yellow-blk},
    green-blk: #{$green-blk},
    blue-blk: #{$blue-blk},
    pink-blk: #{$pink-blk},
    red-blk: #{$red-blk},
    grey-blk: #{$grey-blk},
    mono-blk: #{$mono-blk}
  )
);

.light-theme {
  --text-yellow: #{$yellow-dk};
  --text-green: #{$green-dk};
  --text-blue: #{$blue-dk};
  --text-pink: #{$pink-dk};
  --text-red: #{$red-dk};
  --text-grey: #{$grey-dk};
  --text-mono: #{$mono-dk};

  --bg-yellow: #{$yellow-lt};
  --bg-green: #{$green-lt};
  --bg-blue: #{$blue-lt};
  --bg-pink: #{$pink-lt};
  --bg-red: #{$red-lt};
  --bg-grey: #{$grey-lt};
  --bg-mono: #{$mono-lt};
}

.dark-theme {
  --text-yellow: #{$yellow-lt};
  --text-green: #{$green-lt};
  --text-blue: #{$blue-lt};
  --text-pink: #{$pink-lt};
  --text-red: #{$red-lt};
  --text-grey: #{$grey-lt};
  --text-mono: #{$mono-lt};

  --bg-yellow: #{$yellow-dk};
  --bg-green: #{$green-dk};
  --bg-blue: #{$blue-dk};
  --bg-pink: #{$pink-dk};
  --bg-red: #{$red-dk};
  --bg-grey: #{$grey-dk};
  --bg-mono: #{$mono-dk};
}

// COLOR SUFFIX
//-----------------------------------------------------------
$color-values: ('lt', 'dk');

// COLOR NAMES
//-----------------------------------------------------------
//$color-hues: ('yellow', 'green', 'blue', 'pink', 'red', 'grey', 'mono');

// SCSS COLOR VALUES MAP
//-----------------------------------------------------------
// $color-primary: (
//   yellow-lt: $yellow-lt,
//   yellow-lt-alt: $yellow-lt-alt,
//   yellow-dk: $yellow-dk,
//   yellow-dk-alt: $yellow-dk-alt,

//   green-lt: $green-lt,
//   green-lt-alt: $green-lt-alt,
//   green-dk: $green-dk,
//   green-dk-alt: $green-dk-alt,

//   blue-lt: $blue-lt,
//   blue-lt-alt: $blue-lt-alt,
//   blue-dk: $blue-dk,
//   blue-dk-alt: $blue-dk-alt,

//   pink-lt: $pink-lt,
//   pink-lt-alt: $pink-lt-alt,
//   pink-dk: $pink-dk,
//   pink-dk-alt: $pink-dk-alt,

//   red-lt: $red-lt,
//   red-lt-alt: $red-lt-alt,
//   red-dk: $red-dk,
//   red-dk-alt: $red-dk-alt,

//   grey-lt: $grey-lt,
//   grey-lt-alt: $grey-lt-alt,
//   grey-dk: $grey-dk,
//   grey-dk-alt: $grey-dk-alt,

//   mono-lt: $mono-lt,
//   mono-lt-alt: $mono-lt-alt,
//   mono-dk: $mono-dk,
//   mono-dk-alt: $mono-dk-alt,
// );
