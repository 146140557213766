@import './reset.css';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
:root {
  --max-columns: 18;
  --current-columns: ;
  --spacer: 1rem;
  --card-padding: clamp(2rem, 5.625vw, 1.5rem) clamp(1rem, 5.5vw, 2rem) clamp(2.125rem, 5.5vw, 1.625rem);
}

/* https://codepen.io/kennyglenn/pen/kxqWjP */
/* https://css-tricks.com/snippets/sass/deep-getset-maps/ */
/* https://css-tricks.com/snippets/sass/str-replace-function/ */
:root {
  --text: ;
  --bg: ;
  --accent: ;
  --blk: ;
  --yellow-accent: 255, 200, 0;
  --green-accent: 87, 218, 76;
  --blue-accent: 87, 157, 255;
  --pink-accent: 255, 110, 206;
  --red-accent: 255, 100, 100;
  --grey-accent: 151, 160, 170;
  --mono-accent: 133, 133, 133;
  --yellow-blk: 90, 30, 0;
  --green-blk: 0, 45, 5;
  --blue-blk: 0, 29, 86;
  --pink-blk: 80, 0, 90;
  --red-blk: 80, 7, 0;
  --grey-blk: 18, 19, 26;
  --mono-blk: 30, 30, 30;
}

.light-theme {
  --text-yellow: 160, 90, 0;
  --text-green: 0, 102, 17;
  --text-blue: 11, 75, 203;
  --text-pink: 150, 9, 170;
  --text-red: 153, 13, 0;
  --text-grey: 43, 46, 59;
  --text-mono: 0, 0, 0;
  --bg-yellow: 251, 234, 153;
  --bg-green: 201, 240, 194;
  --bg-blue: 205, 230, 254;
  --bg-pink: 246, 205, 254;
  --bg-red: 254, 209, 205;
  --bg-grey: 221, 224, 227;
  --bg-mono: 245, 245, 245;
}

.dark-theme {
  --text-yellow: 251, 234, 153;
  --text-green: 201, 240, 194;
  --text-blue: 205, 230, 254;
  --text-pink: 246, 205, 254;
  --text-red: 254, 209, 205;
  --text-grey: 221, 224, 227;
  --text-mono: 245, 245, 245;
  --bg-yellow: 160, 90, 0;
  --bg-green: 0, 102, 17;
  --bg-blue: 11, 75, 203;
  --bg-pink: 150, 9, 170;
  --bg-red: 153, 13, 0;
  --bg-grey: 43, 46, 59;
  --bg-mono: 0, 0, 0;
}

.container {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  max-width: calc(var(--max-columns) * 100px);
  margin: 0 auto;
  padding: clamp(3.375rem, 2.0118694362rem + 6.2314540059vw, 6rem) clamp(1rem, -0.0385756677rem + 4.7477744807vw, 3rem);
}
.container.full {
  max-width: 100vw;
  margin: 0;
  padding: 0;
}

.row-1 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-1 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(100px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-1 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-1 > img,
.col-1 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-1 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(100px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-2 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-2 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(200px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-2 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-2 > img,
.col-2 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-2 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(200px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-3 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-3 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(300px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-3 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-3 > img,
.col-3 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-3 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(300px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-4 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-4 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(400px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-4 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-4 > img,
.col-4 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-4 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(400px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-5 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-5 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(500px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-5 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-5 > img,
.col-5 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-5 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(500px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-6 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-6 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(600px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-6 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-6 > img,
.col-6 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-6 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(600px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-7 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-7 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(700px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-7 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-7 > img,
.col-7 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-7 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(700px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-8 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-8 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(800px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-8 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-8 > img,
.col-8 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-8 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(800px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-9 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-9 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(900px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-9 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-9 > img,
.col-9 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-9 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(900px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-10 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-10 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1000px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-10 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-10 > img,
.col-10 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-10 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1000px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-11 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-11 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1100px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-11 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-11 > img,
.col-11 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-11 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1100px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-12 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-12 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1200px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-12 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-12 > img,
.col-12 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-12 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1200px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-13 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-13 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1300px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-13 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-13 > img,
.col-13 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-13 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1300px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-14 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-14 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1400px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-14 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-14 > img,
.col-14 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-14 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1400px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-15 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-15 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1500px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-15 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-15 > img,
.col-15 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-15 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1500px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-16 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-16 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1600px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-16 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-16 > img,
.col-16 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-16 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1600px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-17 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-17 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1700px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-17 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-17 > img,
.col-17 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-17 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1700px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-18 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 512px) {
  .row-18 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1800px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.col-18 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-18 > img,
.col-18 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 512px) {
  .col-18 {
    max-width: calc(var(--current-columns) * 100px);
    flex: 0 1 calc(1800px - clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem) / 2);
  }
}

.row-f1 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f1 {
    flex: 1 1 10%;
    min-width: 275px;
  }
}

.col-f1 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f1 > img,
.col-f1 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f1 {
    flex: 1 1 10%;
    min-width: 275px;
  }
}

.row-f2 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f2 {
    flex: 1 1 20%;
    min-width: 275px;
  }
}

.col-f2 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f2 > img,
.col-f2 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f2 {
    flex: 1 1 20%;
    min-width: 275px;
  }
}

.row-f3 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f3 {
    flex: 1 1 30%;
    min-width: 275px;
  }
}

.col-f3 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f3 > img,
.col-f3 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f3 {
    flex: 1 1 30%;
    min-width: 275px;
  }
}

.row-f4 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f4 {
    flex: 1 1 40%;
    min-width: 275px;
  }
}

.col-f4 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f4 > img,
.col-f4 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f4 {
    flex: 1 1 40%;
    min-width: 275px;
  }
}

.row-f5 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f5 {
    flex: 1 1 50%;
    min-width: 275px;
  }
}

.col-f5 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f5 > img,
.col-f5 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f5 {
    flex: 1 1 50%;
    min-width: 275px;
  }
}

.row-f6 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f6 {
    flex: 1 1 60%;
    min-width: 275px;
  }
}

.col-f6 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f6 > img,
.col-f6 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f6 {
    flex: 1 1 60%;
    min-width: 275px;
  }
}

.row-f7 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f7 {
    flex: 1 1 70%;
    min-width: 275px;
  }
}

.col-f7 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f7 > img,
.col-f7 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f7 {
    flex: 1 1 70%;
    min-width: 275px;
  }
}

.row-f8 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f8 {
    flex: 1 1 80%;
    min-width: 275px;
  }
}

.col-f8 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f8 > img,
.col-f8 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f8 {
    flex: 1 1 80%;
    min-width: 275px;
  }
}

.row-f9 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f9 {
    flex: 1 1 90%;
    min-width: 275px;
  }
}

.col-f9 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f9 > img,
.col-f9 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f9 {
    flex: 1 1 90%;
    min-width: 275px;
  }
}

.row-f10 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: row wrap;
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem) clamp(2rem, 0.4421364985rem + 7.1216617211vw, 5rem);
  place-content: center;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
@media (min-width: 768px) {
  .row-f10 {
    flex: 1 1 100%;
    min-width: 275px;
  }
}

.col-f10 {
  display: flex;
  flex: 1 1 100%;
  transition: false, margin 200ms ease-in-out, padding 200ms ease-in-out, false, gap 200ms ease-in-out, false, false, false;
  flex-flow: column nowrap;
  gap: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  place-content: flex-start;
  place-items: flex-start;
  /*   transition: transition(
    $color: true,
    $size: true,
    $spacer: true,
    $gap: true,
    $ease: ease-out
  ); */
}
.col-f10 > img,
.col-f10 > video {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  .col-f10 {
    flex: 1 1 100%;
    min-width: 275px;
  }
}

.js-start {
  justify-self: flex-start !important;
}

.as-start {
  align-self: flex-start !important;
}

.ps-start {
  place-self: flex-start !important;
}

.js-end {
  justify-self: flex-end !important;
}

.as-end {
  align-self: flex-end !important;
}

.ps-end {
  place-self: flex-end !important;
}

.js-self-start {
  justify-self: self-start !important;
}

.as-self-start {
  align-self: self-start !important;
}

.ps-self-start {
  place-self: self-start !important;
}

.js-self-end {
  justify-self: self-end !important;
}

.as-self-end {
  align-self: self-end !important;
}

.ps-self-end {
  place-self: self-end !important;
}

.js-center {
  justify-self: center !important;
}

.as-center {
  align-self: center !important;
}

.ps-center {
  place-self: center !important;
}

.js-baseline {
  justify-self: baseline !important;
}

.as-baseline {
  align-self: baseline !important;
}

.ps-baseline {
  place-self: baseline !important;
}

.js-stretch {
  justify-self: stretch !important;
}

.as-stretch {
  align-self: stretch !important;
}

.ps-stretch {
  place-self: stretch !important;
}

.ji-start {
  justify-items: flex-start !important;
}

.ai-start {
  align-items: flex-start !important;
}

.pi-start {
  place-items: flex-start !important;
}

.ji-end {
  justify-items: flex-end !important;
}

.ai-end {
  align-items: flex-end !important;
}

.pi-end {
  place-items: flex-end !important;
}

.ji-self-start {
  justify-items: self-start !important;
}

.ai-self-start {
  align-items: self-start !important;
}

.pi-self-start {
  place-items: self-start !important;
}

.ji-self-end {
  justify-items: self-end !important;
}

.ai-self-end {
  align-items: self-end !important;
}

.pi-self-end {
  place-items: self-end !important;
}

.ji-center {
  justify-items: center !important;
}

.ai-center {
  align-items: center !important;
}

.pi-center {
  place-items: center !important;
}

.ji-baseline {
  justify-items: baseline !important;
}

.ai-baseline {
  align-items: baseline !important;
}

.pi-baseline {
  place-items: baseline !important;
}

.ji-stretch {
  justify-items: stretch !important;
}

.ai-stretch {
  align-items: stretch !important;
}

.pi-stretch {
  place-items: stretch !important;
}

.jc-start {
  justify-content: flex-start !important;
}

.ac-start {
  align-content: flex-start !important;
}

.pc-start {
  place-content: flex-start !important;
}

.jc-end {
  justify-content: flex-end !important;
}

.ac-end {
  align-content: flex-end !important;
}

.pc-end {
  place-content: flex-end !important;
}

.jc-center {
  justify-content: center !important;
}

.ac-center {
  align-content: center !important;
}

.pc-center {
  place-content: center !important;
}

.jc-between {
  justify-content: space-between !important;
}

.ac-between {
  align-content: space-between !important;
}

.pc-between {
  place-content: space-between !important;
}

.jc-around {
  justify-content: space-around !important;
}

.ac-around {
  align-content: space-around !important;
}

.pc-around {
  place-content: space-around !important;
}

.jc-evenly {
  justify-content: space-evenly !important;
}

.ac-evenly {
  align-content: space-evenly !important;
}

.pc-evenly {
  place-content: space-evenly !important;
}

.jc-stretch {
  justify-content: stretch !important;
}

.ac-stretch {
  align-content: stretch !important;
}

.pc-stretch {
  place-content: stretch !important;
}

@media (min-width: 0px) {
  .vh-00 {
    min-height: 0vh !important;
  }
  .vh-10 {
    min-height: 10vh !important;
  }
  .vh-20 {
    min-height: 20vh !important;
  }
  .vh-30 {
    min-height: 30vh !important;
  }
  .vh-40 {
    min-height: 40vh !important;
  }
  .vh-50 {
    min-height: 50vh !important;
  }
  .vh-60 {
    min-height: 60vh !important;
  }
  .vh-70 {
    min-height: 70vh !important;
  }
  .vh-80 {
    min-height: 80vh !important;
  }
  .vh-90 {
    min-height: 90vh !important;
  }
  .vh-100 {
    min-height: 100vh !important;
  }
}
@media (min-width: 350px) {
  .vh-xs-00 {
    min-height: 0vh !important;
  }
  .vh-xs-10 {
    min-height: 10vh !important;
  }
  .vh-xs-20 {
    min-height: 20vh !important;
  }
  .vh-xs-30 {
    min-height: 30vh !important;
  }
  .vh-xs-40 {
    min-height: 40vh !important;
  }
  .vh-xs-50 {
    min-height: 50vh !important;
  }
  .vh-xs-60 {
    min-height: 60vh !important;
  }
  .vh-xs-70 {
    min-height: 70vh !important;
  }
  .vh-xs-80 {
    min-height: 80vh !important;
  }
  .vh-xs-90 {
    min-height: 90vh !important;
  }
  .vh-xs-100 {
    min-height: 100vh !important;
  }
}
@media (min-width: 512px) {
  .vh-sm-00 {
    min-height: 0vh !important;
  }
  .vh-sm-10 {
    min-height: 10vh !important;
  }
  .vh-sm-20 {
    min-height: 20vh !important;
  }
  .vh-sm-30 {
    min-height: 30vh !important;
  }
  .vh-sm-40 {
    min-height: 40vh !important;
  }
  .vh-sm-50 {
    min-height: 50vh !important;
  }
  .vh-sm-60 {
    min-height: 60vh !important;
  }
  .vh-sm-70 {
    min-height: 70vh !important;
  }
  .vh-sm-80 {
    min-height: 80vh !important;
  }
  .vh-sm-90 {
    min-height: 90vh !important;
  }
  .vh-sm-100 {
    min-height: 100vh !important;
  }
}
@media (min-width: 768px) {
  .vh-md-00 {
    min-height: 0vh !important;
  }
  .vh-md-10 {
    min-height: 10vh !important;
  }
  .vh-md-20 {
    min-height: 20vh !important;
  }
  .vh-md-30 {
    min-height: 30vh !important;
  }
  .vh-md-40 {
    min-height: 40vh !important;
  }
  .vh-md-50 {
    min-height: 50vh !important;
  }
  .vh-md-60 {
    min-height: 60vh !important;
  }
  .vh-md-70 {
    min-height: 70vh !important;
  }
  .vh-md-80 {
    min-height: 80vh !important;
  }
  .vh-md-90 {
    min-height: 90vh !important;
  }
  .vh-md-100 {
    min-height: 100vh !important;
  }
}
@media (min-width: 1024px) {
  .vh-lg-00 {
    min-height: 0vh !important;
  }
  .vh-lg-10 {
    min-height: 10vh !important;
  }
  .vh-lg-20 {
    min-height: 20vh !important;
  }
  .vh-lg-30 {
    min-height: 30vh !important;
  }
  .vh-lg-40 {
    min-height: 40vh !important;
  }
  .vh-lg-50 {
    min-height: 50vh !important;
  }
  .vh-lg-60 {
    min-height: 60vh !important;
  }
  .vh-lg-70 {
    min-height: 70vh !important;
  }
  .vh-lg-80 {
    min-height: 80vh !important;
  }
  .vh-lg-90 {
    min-height: 90vh !important;
  }
  .vh-lg-100 {
    min-height: 100vh !important;
  }
}
@media (min-width: 1280px) {
  .vh-xl-00 {
    min-height: 0vh !important;
  }
  .vh-xl-10 {
    min-height: 10vh !important;
  }
  .vh-xl-20 {
    min-height: 20vh !important;
  }
  .vh-xl-30 {
    min-height: 30vh !important;
  }
  .vh-xl-40 {
    min-height: 40vh !important;
  }
  .vh-xl-50 {
    min-height: 50vh !important;
  }
  .vh-xl-60 {
    min-height: 60vh !important;
  }
  .vh-xl-70 {
    min-height: 70vh !important;
  }
  .vh-xl-80 {
    min-height: 80vh !important;
  }
  .vh-xl-90 {
    min-height: 90vh !important;
  }
  .vh-xl-100 {
    min-height: 100vh !important;
  }
}
@media (min-width: 1536px) {
  .vh-xxl-00 {
    min-height: 0vh !important;
  }
  .vh-xxl-10 {
    min-height: 10vh !important;
  }
  .vh-xxl-20 {
    min-height: 20vh !important;
  }
  .vh-xxl-30 {
    min-height: 30vh !important;
  }
  .vh-xxl-40 {
    min-height: 40vh !important;
  }
  .vh-xxl-50 {
    min-height: 50vh !important;
  }
  .vh-xxl-60 {
    min-height: 60vh !important;
  }
  .vh-xxl-70 {
    min-height: 70vh !important;
  }
  .vh-xxl-80 {
    min-height: 80vh !important;
  }
  .vh-xxl-90 {
    min-height: 90vh !important;
  }
  .vh-xxl-100 {
    min-height: 100vh !important;
  }
}
.h-10 {
  height: 10% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-40 {
  height: 40% !important;
}

.h-50 {
  height: 50% !important;
}

.h-60 {
  height: 60% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.h-100 {
  height: 100% !important;
}

.vw-10 {
  min-width: 10vw !important;
}

.vw-20 {
  min-width: 20vw !important;
}

.vw-30 {
  min-width: 30vw !important;
}

.vw-40 {
  min-width: 40vw !important;
}

.vw-50 {
  min-width: 50vw !important;
}

.vw-60 {
  min-width: 60vw !important;
}

.vw-70 {
  min-width: 70vw !important;
}

.vw-80 {
  min-width: 80vw !important;
}

.vw-90 {
  min-width: 90vw !important;
}

.vw-100 {
  min-width: 100vw !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.o-10 {
  opacity: 0.1;
}

.o-20 {
  opacity: 0.2;
}

.o-30 {
  opacity: 0.3;
}

.o-40 {
  opacity: 0.4;
}

.o-50 {
  opacity: 0.5;
}

.o-60 {
  opacity: 0.6;
}

.o-70 {
  opacity: 0.7;
}

.o-80 {
  opacity: 0.8;
}

.o-90 {
  opacity: 0.9;
}

@media (min-width: 0px) {
  .px-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .p-0 {
    padding: 0rem !important;
  }
  .px-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-1 {
    padding: 1rem !important;
  }
  .px-2 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .p-2 {
    padding: 2rem !important;
  }
  .px-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .p-3 {
    padding: 3rem !important;
  }
  .px-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .p-4 {
    padding: 4rem !important;
  }
  .px-5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .p-5 {
    padding: 5rem !important;
  }
  .px-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .p-6 {
    padding: 6rem !important;
  }
  .px-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .p-7 {
    padding: 7rem !important;
  }
  .px-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .p-8 {
    padding: 8rem !important;
  }
  .px-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .py-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .p-9 {
    padding: 9rem !important;
  }
  .px-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .p-10 {
    padding: 10rem !important;
  }
  .pt-0 {
    padding-top: 0rem !important;
  }
  .pt-1 {
    padding-top: 1rem !important;
  }
  .pt-2 {
    padding-top: 2rem !important;
  }
  .pt-3 {
    padding-top: 3rem !important;
  }
  .pt-4 {
    padding-top: 4rem !important;
  }
  .pt-5 {
    padding-top: 5rem !important;
  }
  .pt-6 {
    padding-top: 6rem !important;
  }
  .pt-7 {
    padding-top: 7rem !important;
  }
  .pt-8 {
    padding-top: 8rem !important;
  }
  .pt-9 {
    padding-top: 9rem !important;
  }
  .pt-10 {
    padding-top: 10rem !important;
  }
  .pr-0 {
    padding-right: 0rem !important;
  }
  .pr-1 {
    padding-right: 1rem !important;
  }
  .pr-2 {
    padding-right: 2rem !important;
  }
  .pr-3 {
    padding-right: 3rem !important;
  }
  .pr-4 {
    padding-right: 4rem !important;
  }
  .pr-5 {
    padding-right: 5rem !important;
  }
  .pr-6 {
    padding-right: 6rem !important;
  }
  .pr-7 {
    padding-right: 7rem !important;
  }
  .pr-8 {
    padding-right: 8rem !important;
  }
  .pr-9 {
    padding-right: 9rem !important;
  }
  .pr-10 {
    padding-right: 10rem !important;
  }
  .pb-0 {
    padding-bottom: 0rem !important;
  }
  .pb-1 {
    padding-bottom: 1rem !important;
  }
  .pb-2 {
    padding-bottom: 2rem !important;
  }
  .pb-3 {
    padding-bottom: 3rem !important;
  }
  .pb-4 {
    padding-bottom: 4rem !important;
  }
  .pb-5 {
    padding-bottom: 5rem !important;
  }
  .pb-6 {
    padding-bottom: 6rem !important;
  }
  .pb-7 {
    padding-bottom: 7rem !important;
  }
  .pb-8 {
    padding-bottom: 8rem !important;
  }
  .pb-9 {
    padding-bottom: 9rem !important;
  }
  .pb-10 {
    padding-bottom: 10rem !important;
  }
  .pl-0 {
    padding-left: 0rem !important;
  }
  .pl-1 {
    padding-left: 1rem !important;
  }
  .pl-2 {
    padding-left: 2rem !important;
  }
  .pl-3 {
    padding-left: 3rem !important;
  }
  .pl-4 {
    padding-left: 4rem !important;
  }
  .pl-5 {
    padding-left: 5rem !important;
  }
  .pl-6 {
    padding-left: 6rem !important;
  }
  .pl-7 {
    padding-left: 7rem !important;
  }
  .pl-8 {
    padding-left: 8rem !important;
  }
  .pl-9 {
    padding-left: 9rem !important;
  }
  .pl-10 {
    padding-left: 10rem !important;
  }
  .mx-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .m-0 {
    margin: 0rem !important;
  }
  .mx-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-1 {
    margin: 1rem !important;
  }
  .mx-2 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .my-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .m-2 {
    margin: 2rem !important;
  }
  .mx-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-3 {
    margin: 3rem !important;
  }
  .mx-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .my-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .m-4 {
    margin: 4rem !important;
  }
  .mx-5 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .my-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .m-5 {
    margin: 5rem !important;
  }
  .mx-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .my-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .m-6 {
    margin: 6rem !important;
  }
  .mx-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .my-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .m-7 {
    margin: 7rem !important;
  }
  .mx-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .my-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .m-8 {
    margin: 8rem !important;
  }
  .mx-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .my-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .m-9 {
    margin: 9rem !important;
  }
  .mx-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .my-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .m-10 {
    margin: 10rem !important;
  }
  .mt-0 {
    margin-top: 0rem !important;
  }
  .mt-1 {
    margin-top: 1rem !important;
  }
  .mt-2 {
    margin-top: 2rem !important;
  }
  .mt-3 {
    margin-top: 3rem !important;
  }
  .mt-4 {
    margin-top: 4rem !important;
  }
  .mt-5 {
    margin-top: 5rem !important;
  }
  .mt-6 {
    margin-top: 6rem !important;
  }
  .mt-7 {
    margin-top: 7rem !important;
  }
  .mt-8 {
    margin-top: 8rem !important;
  }
  .mt-9 {
    margin-top: 9rem !important;
  }
  .mt-10 {
    margin-top: 10rem !important;
  }
  .mr-0 {
    margin-right: 0rem !important;
  }
  .mr-1 {
    margin-right: 1rem !important;
  }
  .mr-2 {
    margin-right: 2rem !important;
  }
  .mr-3 {
    margin-right: 3rem !important;
  }
  .mr-4 {
    margin-right: 4rem !important;
  }
  .mr-5 {
    margin-right: 5rem !important;
  }
  .mr-6 {
    margin-right: 6rem !important;
  }
  .mr-7 {
    margin-right: 7rem !important;
  }
  .mr-8 {
    margin-right: 8rem !important;
  }
  .mr-9 {
    margin-right: 9rem !important;
  }
  .mr-10 {
    margin-right: 10rem !important;
  }
  .mb-0 {
    margin-bottom: 0rem !important;
  }
  .mb-1 {
    margin-bottom: 1rem !important;
  }
  .mb-2 {
    margin-bottom: 2rem !important;
  }
  .mb-3 {
    margin-bottom: 3rem !important;
  }
  .mb-4 {
    margin-bottom: 4rem !important;
  }
  .mb-5 {
    margin-bottom: 5rem !important;
  }
  .mb-6 {
    margin-bottom: 6rem !important;
  }
  .mb-7 {
    margin-bottom: 7rem !important;
  }
  .mb-8 {
    margin-bottom: 8rem !important;
  }
  .mb-9 {
    margin-bottom: 9rem !important;
  }
  .mb-10 {
    margin-bottom: 10rem !important;
  }
  .ml-0 {
    margin-left: 0rem !important;
  }
  .ml-1 {
    margin-left: 1rem !important;
  }
  .ml-2 {
    margin-left: 2rem !important;
  }
  .ml-3 {
    margin-left: 3rem !important;
  }
  .ml-4 {
    margin-left: 4rem !important;
  }
  .ml-5 {
    margin-left: 5rem !important;
  }
  .ml-6 {
    margin-left: 6rem !important;
  }
  .ml-7 {
    margin-left: 7rem !important;
  }
  .ml-8 {
    margin-left: 8rem !important;
  }
  .ml-9 {
    margin-left: 9rem !important;
  }
  .ml-10 {
    margin-left: 10rem !important;
  }
}
@media (min-width: 350px) {
  .px-xs-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .py-xs-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .p-xs-0 {
    padding: 0rem !important;
  }
  .px-xs-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-xs-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-xs-1 {
    padding: 1rem !important;
  }
  .px-xs-2 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-xs-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .p-xs-2 {
    padding: 2rem !important;
  }
  .px-xs-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xs-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .p-xs-3 {
    padding: 3rem !important;
  }
  .px-xs-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-xs-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .p-xs-4 {
    padding: 4rem !important;
  }
  .px-xs-5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-xs-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .p-xs-5 {
    padding: 5rem !important;
  }
  .px-xs-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-xs-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .p-xs-6 {
    padding: 6rem !important;
  }
  .px-xs-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xs-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .p-xs-7 {
    padding: 7rem !important;
  }
  .px-xs-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-xs-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .p-xs-8 {
    padding: 8rem !important;
  }
  .px-xs-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .py-xs-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .p-xs-9 {
    padding: 9rem !important;
  }
  .px-xs-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-xs-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .p-xs-10 {
    padding: 10rem !important;
  }
  .pt-xs-0 {
    padding-top: 0rem !important;
  }
  .pt-xs-1 {
    padding-top: 1rem !important;
  }
  .pt-xs-2 {
    padding-top: 2rem !important;
  }
  .pt-xs-3 {
    padding-top: 3rem !important;
  }
  .pt-xs-4 {
    padding-top: 4rem !important;
  }
  .pt-xs-5 {
    padding-top: 5rem !important;
  }
  .pt-xs-6 {
    padding-top: 6rem !important;
  }
  .pt-xs-7 {
    padding-top: 7rem !important;
  }
  .pt-xs-8 {
    padding-top: 8rem !important;
  }
  .pt-xs-9 {
    padding-top: 9rem !important;
  }
  .pt-xs-10 {
    padding-top: 10rem !important;
  }
  .pr-xs-0 {
    padding-right: 0rem !important;
  }
  .pr-xs-1 {
    padding-right: 1rem !important;
  }
  .pr-xs-2 {
    padding-right: 2rem !important;
  }
  .pr-xs-3 {
    padding-right: 3rem !important;
  }
  .pr-xs-4 {
    padding-right: 4rem !important;
  }
  .pr-xs-5 {
    padding-right: 5rem !important;
  }
  .pr-xs-6 {
    padding-right: 6rem !important;
  }
  .pr-xs-7 {
    padding-right: 7rem !important;
  }
  .pr-xs-8 {
    padding-right: 8rem !important;
  }
  .pr-xs-9 {
    padding-right: 9rem !important;
  }
  .pr-xs-10 {
    padding-right: 10rem !important;
  }
  .pb-xs-0 {
    padding-bottom: 0rem !important;
  }
  .pb-xs-1 {
    padding-bottom: 1rem !important;
  }
  .pb-xs-2 {
    padding-bottom: 2rem !important;
  }
  .pb-xs-3 {
    padding-bottom: 3rem !important;
  }
  .pb-xs-4 {
    padding-bottom: 4rem !important;
  }
  .pb-xs-5 {
    padding-bottom: 5rem !important;
  }
  .pb-xs-6 {
    padding-bottom: 6rem !important;
  }
  .pb-xs-7 {
    padding-bottom: 7rem !important;
  }
  .pb-xs-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xs-9 {
    padding-bottom: 9rem !important;
  }
  .pb-xs-10 {
    padding-bottom: 10rem !important;
  }
  .pl-xs-0 {
    padding-left: 0rem !important;
  }
  .pl-xs-1 {
    padding-left: 1rem !important;
  }
  .pl-xs-2 {
    padding-left: 2rem !important;
  }
  .pl-xs-3 {
    padding-left: 3rem !important;
  }
  .pl-xs-4 {
    padding-left: 4rem !important;
  }
  .pl-xs-5 {
    padding-left: 5rem !important;
  }
  .pl-xs-6 {
    padding-left: 6rem !important;
  }
  .pl-xs-7 {
    padding-left: 7rem !important;
  }
  .pl-xs-8 {
    padding-left: 8rem !important;
  }
  .pl-xs-9 {
    padding-left: 9rem !important;
  }
  .pl-xs-10 {
    padding-left: 10rem !important;
  }
  .mx-xs-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .my-xs-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .m-xs-0 {
    margin: 0rem !important;
  }
  .mx-xs-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-xs-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-xs-1 {
    margin: 1rem !important;
  }
  .mx-xs-2 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .my-xs-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .m-xs-2 {
    margin: 2rem !important;
  }
  .mx-xs-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-xs-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-xs-3 {
    margin: 3rem !important;
  }
  .mx-xs-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .my-xs-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .m-xs-4 {
    margin: 4rem !important;
  }
  .mx-xs-5 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .my-xs-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .m-xs-5 {
    margin: 5rem !important;
  }
  .mx-xs-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .my-xs-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .m-xs-6 {
    margin: 6rem !important;
  }
  .mx-xs-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .my-xs-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .m-xs-7 {
    margin: 7rem !important;
  }
  .mx-xs-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .my-xs-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .m-xs-8 {
    margin: 8rem !important;
  }
  .mx-xs-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .my-xs-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .m-xs-9 {
    margin: 9rem !important;
  }
  .mx-xs-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .my-xs-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .m-xs-10 {
    margin: 10rem !important;
  }
  .mt-xs-0 {
    margin-top: 0rem !important;
  }
  .mt-xs-1 {
    margin-top: 1rem !important;
  }
  .mt-xs-2 {
    margin-top: 2rem !important;
  }
  .mt-xs-3 {
    margin-top: 3rem !important;
  }
  .mt-xs-4 {
    margin-top: 4rem !important;
  }
  .mt-xs-5 {
    margin-top: 5rem !important;
  }
  .mt-xs-6 {
    margin-top: 6rem !important;
  }
  .mt-xs-7 {
    margin-top: 7rem !important;
  }
  .mt-xs-8 {
    margin-top: 8rem !important;
  }
  .mt-xs-9 {
    margin-top: 9rem !important;
  }
  .mt-xs-10 {
    margin-top: 10rem !important;
  }
  .mr-xs-0 {
    margin-right: 0rem !important;
  }
  .mr-xs-1 {
    margin-right: 1rem !important;
  }
  .mr-xs-2 {
    margin-right: 2rem !important;
  }
  .mr-xs-3 {
    margin-right: 3rem !important;
  }
  .mr-xs-4 {
    margin-right: 4rem !important;
  }
  .mr-xs-5 {
    margin-right: 5rem !important;
  }
  .mr-xs-6 {
    margin-right: 6rem !important;
  }
  .mr-xs-7 {
    margin-right: 7rem !important;
  }
  .mr-xs-8 {
    margin-right: 8rem !important;
  }
  .mr-xs-9 {
    margin-right: 9rem !important;
  }
  .mr-xs-10 {
    margin-right: 10rem !important;
  }
  .mb-xs-0 {
    margin-bottom: 0rem !important;
  }
  .mb-xs-1 {
    margin-bottom: 1rem !important;
  }
  .mb-xs-2 {
    margin-bottom: 2rem !important;
  }
  .mb-xs-3 {
    margin-bottom: 3rem !important;
  }
  .mb-xs-4 {
    margin-bottom: 4rem !important;
  }
  .mb-xs-5 {
    margin-bottom: 5rem !important;
  }
  .mb-xs-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xs-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xs-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xs-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xs-10 {
    margin-bottom: 10rem !important;
  }
  .ml-xs-0 {
    margin-left: 0rem !important;
  }
  .ml-xs-1 {
    margin-left: 1rem !important;
  }
  .ml-xs-2 {
    margin-left: 2rem !important;
  }
  .ml-xs-3 {
    margin-left: 3rem !important;
  }
  .ml-xs-4 {
    margin-left: 4rem !important;
  }
  .ml-xs-5 {
    margin-left: 5rem !important;
  }
  .ml-xs-6 {
    margin-left: 6rem !important;
  }
  .ml-xs-7 {
    margin-left: 7rem !important;
  }
  .ml-xs-8 {
    margin-left: 8rem !important;
  }
  .ml-xs-9 {
    margin-left: 9rem !important;
  }
  .ml-xs-10 {
    margin-left: 10rem !important;
  }
}
@media (min-width: 512px) {
  .px-sm-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .py-sm-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .p-sm-0 {
    padding: 0rem !important;
  }
  .px-sm-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-sm-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-sm-1 {
    padding: 1rem !important;
  }
  .px-sm-2 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-sm-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .p-sm-2 {
    padding: 2rem !important;
  }
  .px-sm-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .p-sm-3 {
    padding: 3rem !important;
  }
  .px-sm-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-sm-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .p-sm-4 {
    padding: 4rem !important;
  }
  .px-sm-5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-sm-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .p-sm-5 {
    padding: 5rem !important;
  }
  .px-sm-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-sm-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .p-sm-6 {
    padding: 6rem !important;
  }
  .px-sm-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-sm-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .p-sm-7 {
    padding: 7rem !important;
  }
  .px-sm-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-sm-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .p-sm-8 {
    padding: 8rem !important;
  }
  .px-sm-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .py-sm-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .p-sm-9 {
    padding: 9rem !important;
  }
  .px-sm-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-sm-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .p-sm-10 {
    padding: 10rem !important;
  }
  .pt-sm-0 {
    padding-top: 0rem !important;
  }
  .pt-sm-1 {
    padding-top: 1rem !important;
  }
  .pt-sm-2 {
    padding-top: 2rem !important;
  }
  .pt-sm-3 {
    padding-top: 3rem !important;
  }
  .pt-sm-4 {
    padding-top: 4rem !important;
  }
  .pt-sm-5 {
    padding-top: 5rem !important;
  }
  .pt-sm-6 {
    padding-top: 6rem !important;
  }
  .pt-sm-7 {
    padding-top: 7rem !important;
  }
  .pt-sm-8 {
    padding-top: 8rem !important;
  }
  .pt-sm-9 {
    padding-top: 9rem !important;
  }
  .pt-sm-10 {
    padding-top: 10rem !important;
  }
  .pr-sm-0 {
    padding-right: 0rem !important;
  }
  .pr-sm-1 {
    padding-right: 1rem !important;
  }
  .pr-sm-2 {
    padding-right: 2rem !important;
  }
  .pr-sm-3 {
    padding-right: 3rem !important;
  }
  .pr-sm-4 {
    padding-right: 4rem !important;
  }
  .pr-sm-5 {
    padding-right: 5rem !important;
  }
  .pr-sm-6 {
    padding-right: 6rem !important;
  }
  .pr-sm-7 {
    padding-right: 7rem !important;
  }
  .pr-sm-8 {
    padding-right: 8rem !important;
  }
  .pr-sm-9 {
    padding-right: 9rem !important;
  }
  .pr-sm-10 {
    padding-right: 10rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0rem !important;
  }
  .pb-sm-1 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 7rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 9rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-0 {
    padding-left: 0rem !important;
  }
  .pl-sm-1 {
    padding-left: 1rem !important;
  }
  .pl-sm-2 {
    padding-left: 2rem !important;
  }
  .pl-sm-3 {
    padding-left: 3rem !important;
  }
  .pl-sm-4 {
    padding-left: 4rem !important;
  }
  .pl-sm-5 {
    padding-left: 5rem !important;
  }
  .pl-sm-6 {
    padding-left: 6rem !important;
  }
  .pl-sm-7 {
    padding-left: 7rem !important;
  }
  .pl-sm-8 {
    padding-left: 8rem !important;
  }
  .pl-sm-9 {
    padding-left: 9rem !important;
  }
  .pl-sm-10 {
    padding-left: 10rem !important;
  }
  .mx-sm-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .my-sm-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .m-sm-0 {
    margin: 0rem !important;
  }
  .mx-sm-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-sm-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-sm-1 {
    margin: 1rem !important;
  }
  .mx-sm-2 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .my-sm-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .m-sm-2 {
    margin: 2rem !important;
  }
  .mx-sm-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-sm-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-sm-3 {
    margin: 3rem !important;
  }
  .mx-sm-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .my-sm-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .m-sm-4 {
    margin: 4rem !important;
  }
  .mx-sm-5 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .my-sm-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .m-sm-5 {
    margin: 5rem !important;
  }
  .mx-sm-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .my-sm-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .m-sm-6 {
    margin: 6rem !important;
  }
  .mx-sm-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .my-sm-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .m-sm-7 {
    margin: 7rem !important;
  }
  .mx-sm-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .my-sm-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .m-sm-8 {
    margin: 8rem !important;
  }
  .mx-sm-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .my-sm-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .m-sm-9 {
    margin: 9rem !important;
  }
  .mx-sm-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .my-sm-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .m-sm-10 {
    margin: 10rem !important;
  }
  .mt-sm-0 {
    margin-top: 0rem !important;
  }
  .mt-sm-1 {
    margin-top: 1rem !important;
  }
  .mt-sm-2 {
    margin-top: 2rem !important;
  }
  .mt-sm-3 {
    margin-top: 3rem !important;
  }
  .mt-sm-4 {
    margin-top: 4rem !important;
  }
  .mt-sm-5 {
    margin-top: 5rem !important;
  }
  .mt-sm-6 {
    margin-top: 6rem !important;
  }
  .mt-sm-7 {
    margin-top: 7rem !important;
  }
  .mt-sm-8 {
    margin-top: 8rem !important;
  }
  .mt-sm-9 {
    margin-top: 9rem !important;
  }
  .mt-sm-10 {
    margin-top: 10rem !important;
  }
  .mr-sm-0 {
    margin-right: 0rem !important;
  }
  .mr-sm-1 {
    margin-right: 1rem !important;
  }
  .mr-sm-2 {
    margin-right: 2rem !important;
  }
  .mr-sm-3 {
    margin-right: 3rem !important;
  }
  .mr-sm-4 {
    margin-right: 4rem !important;
  }
  .mr-sm-5 {
    margin-right: 5rem !important;
  }
  .mr-sm-6 {
    margin-right: 6rem !important;
  }
  .mr-sm-7 {
    margin-right: 7rem !important;
  }
  .mr-sm-8 {
    margin-right: 8rem !important;
  }
  .mr-sm-9 {
    margin-right: 9rem !important;
  }
  .mr-sm-10 {
    margin-right: 10rem !important;
  }
  .mb-sm-0 {
    margin-bottom: 0rem !important;
  }
  .mb-sm-1 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 7rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 9rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-0 {
    margin-left: 0rem !important;
  }
  .ml-sm-1 {
    margin-left: 1rem !important;
  }
  .ml-sm-2 {
    margin-left: 2rem !important;
  }
  .ml-sm-3 {
    margin-left: 3rem !important;
  }
  .ml-sm-4 {
    margin-left: 4rem !important;
  }
  .ml-sm-5 {
    margin-left: 5rem !important;
  }
  .ml-sm-6 {
    margin-left: 6rem !important;
  }
  .ml-sm-7 {
    margin-left: 7rem !important;
  }
  .ml-sm-8 {
    margin-left: 8rem !important;
  }
  .ml-sm-9 {
    margin-left: 9rem !important;
  }
  .ml-sm-10 {
    margin-left: 10rem !important;
  }
}
@media (min-width: 768px) {
  .px-md-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .py-md-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .p-md-0 {
    padding: 0rem !important;
  }
  .px-md-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-md-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-md-1 {
    padding: 1rem !important;
  }
  .px-md-2 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-md-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .p-md-2 {
    padding: 2rem !important;
  }
  .px-md-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .p-md-3 {
    padding: 3rem !important;
  }
  .px-md-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-md-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .p-md-4 {
    padding: 4rem !important;
  }
  .px-md-5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-md-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .p-md-5 {
    padding: 5rem !important;
  }
  .px-md-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-md-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .p-md-6 {
    padding: 6rem !important;
  }
  .px-md-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-md-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .p-md-7 {
    padding: 7rem !important;
  }
  .px-md-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-md-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .p-md-8 {
    padding: 8rem !important;
  }
  .px-md-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .py-md-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .p-md-9 {
    padding: 9rem !important;
  }
  .px-md-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-md-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .p-md-10 {
    padding: 10rem !important;
  }
  .pt-md-0 {
    padding-top: 0rem !important;
  }
  .pt-md-1 {
    padding-top: 1rem !important;
  }
  .pt-md-2 {
    padding-top: 2rem !important;
  }
  .pt-md-3 {
    padding-top: 3rem !important;
  }
  .pt-md-4 {
    padding-top: 4rem !important;
  }
  .pt-md-5 {
    padding-top: 5rem !important;
  }
  .pt-md-6 {
    padding-top: 6rem !important;
  }
  .pt-md-7 {
    padding-top: 7rem !important;
  }
  .pt-md-8 {
    padding-top: 8rem !important;
  }
  .pt-md-9 {
    padding-top: 9rem !important;
  }
  .pt-md-10 {
    padding-top: 10rem !important;
  }
  .pr-md-0 {
    padding-right: 0rem !important;
  }
  .pr-md-1 {
    padding-right: 1rem !important;
  }
  .pr-md-2 {
    padding-right: 2rem !important;
  }
  .pr-md-3 {
    padding-right: 3rem !important;
  }
  .pr-md-4 {
    padding-right: 4rem !important;
  }
  .pr-md-5 {
    padding-right: 5rem !important;
  }
  .pr-md-6 {
    padding-right: 6rem !important;
  }
  .pr-md-7 {
    padding-right: 7rem !important;
  }
  .pr-md-8 {
    padding-right: 8rem !important;
  }
  .pr-md-9 {
    padding-right: 9rem !important;
  }
  .pr-md-10 {
    padding-right: 10rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0rem !important;
  }
  .pb-md-1 {
    padding-bottom: 1rem !important;
  }
  .pb-md-2 {
    padding-bottom: 2rem !important;
  }
  .pb-md-3 {
    padding-bottom: 3rem !important;
  }
  .pb-md-4 {
    padding-bottom: 4rem !important;
  }
  .pb-md-5 {
    padding-bottom: 5rem !important;
  }
  .pb-md-6 {
    padding-bottom: 6rem !important;
  }
  .pb-md-7 {
    padding-bottom: 7rem !important;
  }
  .pb-md-8 {
    padding-bottom: 8rem !important;
  }
  .pb-md-9 {
    padding-bottom: 9rem !important;
  }
  .pb-md-10 {
    padding-bottom: 10rem !important;
  }
  .pl-md-0 {
    padding-left: 0rem !important;
  }
  .pl-md-1 {
    padding-left: 1rem !important;
  }
  .pl-md-2 {
    padding-left: 2rem !important;
  }
  .pl-md-3 {
    padding-left: 3rem !important;
  }
  .pl-md-4 {
    padding-left: 4rem !important;
  }
  .pl-md-5 {
    padding-left: 5rem !important;
  }
  .pl-md-6 {
    padding-left: 6rem !important;
  }
  .pl-md-7 {
    padding-left: 7rem !important;
  }
  .pl-md-8 {
    padding-left: 8rem !important;
  }
  .pl-md-9 {
    padding-left: 9rem !important;
  }
  .pl-md-10 {
    padding-left: 10rem !important;
  }
  .mx-md-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .my-md-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .m-md-0 {
    margin: 0rem !important;
  }
  .mx-md-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-md-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-md-1 {
    margin: 1rem !important;
  }
  .mx-md-2 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .my-md-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .m-md-2 {
    margin: 2rem !important;
  }
  .mx-md-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-md-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-md-3 {
    margin: 3rem !important;
  }
  .mx-md-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .my-md-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .m-md-4 {
    margin: 4rem !important;
  }
  .mx-md-5 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .my-md-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .m-md-5 {
    margin: 5rem !important;
  }
  .mx-md-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .my-md-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .m-md-6 {
    margin: 6rem !important;
  }
  .mx-md-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .my-md-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .m-md-7 {
    margin: 7rem !important;
  }
  .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .my-md-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .m-md-8 {
    margin: 8rem !important;
  }
  .mx-md-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .my-md-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .m-md-9 {
    margin: 9rem !important;
  }
  .mx-md-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .my-md-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .m-md-10 {
    margin: 10rem !important;
  }
  .mt-md-0 {
    margin-top: 0rem !important;
  }
  .mt-md-1 {
    margin-top: 1rem !important;
  }
  .mt-md-2 {
    margin-top: 2rem !important;
  }
  .mt-md-3 {
    margin-top: 3rem !important;
  }
  .mt-md-4 {
    margin-top: 4rem !important;
  }
  .mt-md-5 {
    margin-top: 5rem !important;
  }
  .mt-md-6 {
    margin-top: 6rem !important;
  }
  .mt-md-7 {
    margin-top: 7rem !important;
  }
  .mt-md-8 {
    margin-top: 8rem !important;
  }
  .mt-md-9 {
    margin-top: 9rem !important;
  }
  .mt-md-10 {
    margin-top: 10rem !important;
  }
  .mr-md-0 {
    margin-right: 0rem !important;
  }
  .mr-md-1 {
    margin-right: 1rem !important;
  }
  .mr-md-2 {
    margin-right: 2rem !important;
  }
  .mr-md-3 {
    margin-right: 3rem !important;
  }
  .mr-md-4 {
    margin-right: 4rem !important;
  }
  .mr-md-5 {
    margin-right: 5rem !important;
  }
  .mr-md-6 {
    margin-right: 6rem !important;
  }
  .mr-md-7 {
    margin-right: 7rem !important;
  }
  .mr-md-8 {
    margin-right: 8rem !important;
  }
  .mr-md-9 {
    margin-right: 9rem !important;
  }
  .mr-md-10 {
    margin-right: 10rem !important;
  }
  .mb-md-0 {
    margin-bottom: 0rem !important;
  }
  .mb-md-1 {
    margin-bottom: 1rem !important;
  }
  .mb-md-2 {
    margin-bottom: 2rem !important;
  }
  .mb-md-3 {
    margin-bottom: 3rem !important;
  }
  .mb-md-4 {
    margin-bottom: 4rem !important;
  }
  .mb-md-5 {
    margin-bottom: 5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 6rem !important;
  }
  .mb-md-7 {
    margin-bottom: 7rem !important;
  }
  .mb-md-8 {
    margin-bottom: 8rem !important;
  }
  .mb-md-9 {
    margin-bottom: 9rem !important;
  }
  .mb-md-10 {
    margin-bottom: 10rem !important;
  }
  .ml-md-0 {
    margin-left: 0rem !important;
  }
  .ml-md-1 {
    margin-left: 1rem !important;
  }
  .ml-md-2 {
    margin-left: 2rem !important;
  }
  .ml-md-3 {
    margin-left: 3rem !important;
  }
  .ml-md-4 {
    margin-left: 4rem !important;
  }
  .ml-md-5 {
    margin-left: 5rem !important;
  }
  .ml-md-6 {
    margin-left: 6rem !important;
  }
  .ml-md-7 {
    margin-left: 7rem !important;
  }
  .ml-md-8 {
    margin-left: 8rem !important;
  }
  .ml-md-9 {
    margin-left: 9rem !important;
  }
  .ml-md-10 {
    margin-left: 10rem !important;
  }
}
@media (min-width: 1024px) {
  .px-lg-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .py-lg-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .p-lg-0 {
    padding: 0rem !important;
  }
  .px-lg-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-lg-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-lg-1 {
    padding: 1rem !important;
  }
  .px-lg-2 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-lg-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .p-lg-2 {
    padding: 2rem !important;
  }
  .px-lg-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .p-lg-3 {
    padding: 3rem !important;
  }
  .px-lg-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-lg-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .p-lg-4 {
    padding: 4rem !important;
  }
  .px-lg-5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-lg-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .p-lg-5 {
    padding: 5rem !important;
  }
  .px-lg-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-lg-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .p-lg-6 {
    padding: 6rem !important;
  }
  .px-lg-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-lg-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .p-lg-7 {
    padding: 7rem !important;
  }
  .px-lg-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-lg-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .p-lg-8 {
    padding: 8rem !important;
  }
  .px-lg-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .py-lg-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .p-lg-9 {
    padding: 9rem !important;
  }
  .px-lg-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-lg-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .p-lg-10 {
    padding: 10rem !important;
  }
  .pt-lg-0 {
    padding-top: 0rem !important;
  }
  .pt-lg-1 {
    padding-top: 1rem !important;
  }
  .pt-lg-2 {
    padding-top: 2rem !important;
  }
  .pt-lg-3 {
    padding-top: 3rem !important;
  }
  .pt-lg-4 {
    padding-top: 4rem !important;
  }
  .pt-lg-5 {
    padding-top: 5rem !important;
  }
  .pt-lg-6 {
    padding-top: 6rem !important;
  }
  .pt-lg-7 {
    padding-top: 7rem !important;
  }
  .pt-lg-8 {
    padding-top: 8rem !important;
  }
  .pt-lg-9 {
    padding-top: 9rem !important;
  }
  .pt-lg-10 {
    padding-top: 10rem !important;
  }
  .pr-lg-0 {
    padding-right: 0rem !important;
  }
  .pr-lg-1 {
    padding-right: 1rem !important;
  }
  .pr-lg-2 {
    padding-right: 2rem !important;
  }
  .pr-lg-3 {
    padding-right: 3rem !important;
  }
  .pr-lg-4 {
    padding-right: 4rem !important;
  }
  .pr-lg-5 {
    padding-right: 5rem !important;
  }
  .pr-lg-6 {
    padding-right: 6rem !important;
  }
  .pr-lg-7 {
    padding-right: 7rem !important;
  }
  .pr-lg-8 {
    padding-right: 8rem !important;
  }
  .pr-lg-9 {
    padding-right: 9rem !important;
  }
  .pr-lg-10 {
    padding-right: 10rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0rem !important;
  }
  .pb-lg-1 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 7rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 9rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-0 {
    padding-left: 0rem !important;
  }
  .pl-lg-1 {
    padding-left: 1rem !important;
  }
  .pl-lg-2 {
    padding-left: 2rem !important;
  }
  .pl-lg-3 {
    padding-left: 3rem !important;
  }
  .pl-lg-4 {
    padding-left: 4rem !important;
  }
  .pl-lg-5 {
    padding-left: 5rem !important;
  }
  .pl-lg-6 {
    padding-left: 6rem !important;
  }
  .pl-lg-7 {
    padding-left: 7rem !important;
  }
  .pl-lg-8 {
    padding-left: 8rem !important;
  }
  .pl-lg-9 {
    padding-left: 9rem !important;
  }
  .pl-lg-10 {
    padding-left: 10rem !important;
  }
  .mx-lg-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .my-lg-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .m-lg-0 {
    margin: 0rem !important;
  }
  .mx-lg-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-lg-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-lg-1 {
    margin: 1rem !important;
  }
  .mx-lg-2 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .my-lg-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .m-lg-2 {
    margin: 2rem !important;
  }
  .mx-lg-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-lg-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-lg-3 {
    margin: 3rem !important;
  }
  .mx-lg-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .my-lg-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .m-lg-4 {
    margin: 4rem !important;
  }
  .mx-lg-5 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .my-lg-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .m-lg-5 {
    margin: 5rem !important;
  }
  .mx-lg-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .my-lg-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .m-lg-6 {
    margin: 6rem !important;
  }
  .mx-lg-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .my-lg-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .m-lg-7 {
    margin: 7rem !important;
  }
  .mx-lg-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .my-lg-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .m-lg-8 {
    margin: 8rem !important;
  }
  .mx-lg-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .my-lg-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .m-lg-9 {
    margin: 9rem !important;
  }
  .mx-lg-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .my-lg-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .m-lg-10 {
    margin: 10rem !important;
  }
  .mt-lg-0 {
    margin-top: 0rem !important;
  }
  .mt-lg-1 {
    margin-top: 1rem !important;
  }
  .mt-lg-2 {
    margin-top: 2rem !important;
  }
  .mt-lg-3 {
    margin-top: 3rem !important;
  }
  .mt-lg-4 {
    margin-top: 4rem !important;
  }
  .mt-lg-5 {
    margin-top: 5rem !important;
  }
  .mt-lg-6 {
    margin-top: 6rem !important;
  }
  .mt-lg-7 {
    margin-top: 7rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
  .mt-lg-9 {
    margin-top: 9rem !important;
  }
  .mt-lg-10 {
    margin-top: 10rem !important;
  }
  .mr-lg-0 {
    margin-right: 0rem !important;
  }
  .mr-lg-1 {
    margin-right: 1rem !important;
  }
  .mr-lg-2 {
    margin-right: 2rem !important;
  }
  .mr-lg-3 {
    margin-right: 3rem !important;
  }
  .mr-lg-4 {
    margin-right: 4rem !important;
  }
  .mr-lg-5 {
    margin-right: 5rem !important;
  }
  .mr-lg-6 {
    margin-right: 6rem !important;
  }
  .mr-lg-7 {
    margin-right: 7rem !important;
  }
  .mr-lg-8 {
    margin-right: 8rem !important;
  }
  .mr-lg-9 {
    margin-right: 9rem !important;
  }
  .mr-lg-10 {
    margin-right: 10rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-0 {
    margin-left: 0rem !important;
  }
  .ml-lg-1 {
    margin-left: 1rem !important;
  }
  .ml-lg-2 {
    margin-left: 2rem !important;
  }
  .ml-lg-3 {
    margin-left: 3rem !important;
  }
  .ml-lg-4 {
    margin-left: 4rem !important;
  }
  .ml-lg-5 {
    margin-left: 5rem !important;
  }
  .ml-lg-6 {
    margin-left: 6rem !important;
  }
  .ml-lg-7 {
    margin-left: 7rem !important;
  }
  .ml-lg-8 {
    margin-left: 8rem !important;
  }
  .ml-lg-9 {
    margin-left: 9rem !important;
  }
  .ml-lg-10 {
    margin-left: 10rem !important;
  }
}
@media (min-width: 1280px) {
  .px-xl-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .py-xl-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .p-xl-0 {
    padding: 0rem !important;
  }
  .px-xl-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-xl-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-xl-1 {
    padding: 1rem !important;
  }
  .px-xl-2 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-xl-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .p-xl-2 {
    padding: 2rem !important;
  }
  .px-xl-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .p-xl-3 {
    padding: 3rem !important;
  }
  .px-xl-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-xl-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .p-xl-4 {
    padding: 4rem !important;
  }
  .px-xl-5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-xl-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .p-xl-5 {
    padding: 5rem !important;
  }
  .px-xl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-xl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .p-xl-6 {
    padding: 6rem !important;
  }
  .px-xl-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xl-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .p-xl-7 {
    padding: 7rem !important;
  }
  .px-xl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-xl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .p-xl-8 {
    padding: 8rem !important;
  }
  .px-xl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .py-xl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .p-xl-9 {
    padding: 9rem !important;
  }
  .px-xl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-xl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .p-xl-10 {
    padding: 10rem !important;
  }
  .pt-xl-0 {
    padding-top: 0rem !important;
  }
  .pt-xl-1 {
    padding-top: 1rem !important;
  }
  .pt-xl-2 {
    padding-top: 2rem !important;
  }
  .pt-xl-3 {
    padding-top: 3rem !important;
  }
  .pt-xl-4 {
    padding-top: 4rem !important;
  }
  .pt-xl-5 {
    padding-top: 5rem !important;
  }
  .pt-xl-6 {
    padding-top: 6rem !important;
  }
  .pt-xl-7 {
    padding-top: 7rem !important;
  }
  .pt-xl-8 {
    padding-top: 8rem !important;
  }
  .pt-xl-9 {
    padding-top: 9rem !important;
  }
  .pt-xl-10 {
    padding-top: 10rem !important;
  }
  .pr-xl-0 {
    padding-right: 0rem !important;
  }
  .pr-xl-1 {
    padding-right: 1rem !important;
  }
  .pr-xl-2 {
    padding-right: 2rem !important;
  }
  .pr-xl-3 {
    padding-right: 3rem !important;
  }
  .pr-xl-4 {
    padding-right: 4rem !important;
  }
  .pr-xl-5 {
    padding-right: 5rem !important;
  }
  .pr-xl-6 {
    padding-right: 6rem !important;
  }
  .pr-xl-7 {
    padding-right: 7rem !important;
  }
  .pr-xl-8 {
    padding-right: 8rem !important;
  }
  .pr-xl-9 {
    padding-right: 9rem !important;
  }
  .pr-xl-10 {
    padding-right: 10rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0rem !important;
  }
  .pb-xl-1 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 7rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 9rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-0 {
    padding-left: 0rem !important;
  }
  .pl-xl-1 {
    padding-left: 1rem !important;
  }
  .pl-xl-2 {
    padding-left: 2rem !important;
  }
  .pl-xl-3 {
    padding-left: 3rem !important;
  }
  .pl-xl-4 {
    padding-left: 4rem !important;
  }
  .pl-xl-5 {
    padding-left: 5rem !important;
  }
  .pl-xl-6 {
    padding-left: 6rem !important;
  }
  .pl-xl-7 {
    padding-left: 7rem !important;
  }
  .pl-xl-8 {
    padding-left: 8rem !important;
  }
  .pl-xl-9 {
    padding-left: 9rem !important;
  }
  .pl-xl-10 {
    padding-left: 10rem !important;
  }
  .mx-xl-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .my-xl-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .m-xl-0 {
    margin: 0rem !important;
  }
  .mx-xl-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-xl-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-xl-1 {
    margin: 1rem !important;
  }
  .mx-xl-2 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .my-xl-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .m-xl-2 {
    margin: 2rem !important;
  }
  .mx-xl-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-xl-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-xl-3 {
    margin: 3rem !important;
  }
  .mx-xl-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .my-xl-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .m-xl-4 {
    margin: 4rem !important;
  }
  .mx-xl-5 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .my-xl-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .m-xl-5 {
    margin: 5rem !important;
  }
  .mx-xl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .my-xl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .m-xl-6 {
    margin: 6rem !important;
  }
  .mx-xl-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .my-xl-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .m-xl-7 {
    margin: 7rem !important;
  }
  .mx-xl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .my-xl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .m-xl-8 {
    margin: 8rem !important;
  }
  .mx-xl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .my-xl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .m-xl-9 {
    margin: 9rem !important;
  }
  .mx-xl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .my-xl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .m-xl-10 {
    margin: 10rem !important;
  }
  .mt-xl-0 {
    margin-top: 0rem !important;
  }
  .mt-xl-1 {
    margin-top: 1rem !important;
  }
  .mt-xl-2 {
    margin-top: 2rem !important;
  }
  .mt-xl-3 {
    margin-top: 3rem !important;
  }
  .mt-xl-4 {
    margin-top: 4rem !important;
  }
  .mt-xl-5 {
    margin-top: 5rem !important;
  }
  .mt-xl-6 {
    margin-top: 6rem !important;
  }
  .mt-xl-7 {
    margin-top: 7rem !important;
  }
  .mt-xl-8 {
    margin-top: 8rem !important;
  }
  .mt-xl-9 {
    margin-top: 9rem !important;
  }
  .mt-xl-10 {
    margin-top: 10rem !important;
  }
  .mr-xl-0 {
    margin-right: 0rem !important;
  }
  .mr-xl-1 {
    margin-right: 1rem !important;
  }
  .mr-xl-2 {
    margin-right: 2rem !important;
  }
  .mr-xl-3 {
    margin-right: 3rem !important;
  }
  .mr-xl-4 {
    margin-right: 4rem !important;
  }
  .mr-xl-5 {
    margin-right: 5rem !important;
  }
  .mr-xl-6 {
    margin-right: 6rem !important;
  }
  .mr-xl-7 {
    margin-right: 7rem !important;
  }
  .mr-xl-8 {
    margin-right: 8rem !important;
  }
  .mr-xl-9 {
    margin-right: 9rem !important;
  }
  .mr-xl-10 {
    margin-right: 10rem !important;
  }
  .mb-xl-0 {
    margin-bottom: 0rem !important;
  }
  .mb-xl-1 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-0 {
    margin-left: 0rem !important;
  }
  .ml-xl-1 {
    margin-left: 1rem !important;
  }
  .ml-xl-2 {
    margin-left: 2rem !important;
  }
  .ml-xl-3 {
    margin-left: 3rem !important;
  }
  .ml-xl-4 {
    margin-left: 4rem !important;
  }
  .ml-xl-5 {
    margin-left: 5rem !important;
  }
  .ml-xl-6 {
    margin-left: 6rem !important;
  }
  .ml-xl-7 {
    margin-left: 7rem !important;
  }
  .ml-xl-8 {
    margin-left: 8rem !important;
  }
  .ml-xl-9 {
    margin-left: 9rem !important;
  }
  .ml-xl-10 {
    margin-left: 10rem !important;
  }
}
@media (min-width: 1536px) {
  .px-xxl-0 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .py-xxl-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .p-xxl-0 {
    padding: 0rem !important;
  }
  .px-xxl-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .py-xxl-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .p-xxl-1 {
    padding: 1rem !important;
  }
  .px-xxl-2 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .py-xxl-2 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .p-xxl-2 {
    padding: 2rem !important;
  }
  .px-xxl-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .p-xxl-3 {
    padding: 3rem !important;
  }
  .px-xxl-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .py-xxl-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .p-xxl-4 {
    padding: 4rem !important;
  }
  .px-xxl-5 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .py-xxl-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .p-xxl-5 {
    padding: 5rem !important;
  }
  .px-xxl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .py-xxl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .p-xxl-6 {
    padding: 6rem !important;
  }
  .px-xxl-7 {
    padding-right: 7rem !important;
    padding-left: 7rem !important;
  }
  .py-xxl-7 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }
  .p-xxl-7 {
    padding: 7rem !important;
  }
  .px-xxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .py-xxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .p-xxl-8 {
    padding: 8rem !important;
  }
  .px-xxl-9 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .py-xxl-9 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .p-xxl-9 {
    padding: 9rem !important;
  }
  .px-xxl-10 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .py-xxl-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .p-xxl-10 {
    padding: 10rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0rem !important;
  }
  .pt-xxl-1 {
    padding-top: 1rem !important;
  }
  .pt-xxl-2 {
    padding-top: 2rem !important;
  }
  .pt-xxl-3 {
    padding-top: 3rem !important;
  }
  .pt-xxl-4 {
    padding-top: 4rem !important;
  }
  .pt-xxl-5 {
    padding-top: 5rem !important;
  }
  .pt-xxl-6 {
    padding-top: 6rem !important;
  }
  .pt-xxl-7 {
    padding-top: 7rem !important;
  }
  .pt-xxl-8 {
    padding-top: 8rem !important;
  }
  .pt-xxl-9 {
    padding-top: 9rem !important;
  }
  .pt-xxl-10 {
    padding-top: 10rem !important;
  }
  .pr-xxl-0 {
    padding-right: 0rem !important;
  }
  .pr-xxl-1 {
    padding-right: 1rem !important;
  }
  .pr-xxl-2 {
    padding-right: 2rem !important;
  }
  .pr-xxl-3 {
    padding-right: 3rem !important;
  }
  .pr-xxl-4 {
    padding-right: 4rem !important;
  }
  .pr-xxl-5 {
    padding-right: 5rem !important;
  }
  .pr-xxl-6 {
    padding-right: 6rem !important;
  }
  .pr-xxl-7 {
    padding-right: 7rem !important;
  }
  .pr-xxl-8 {
    padding-right: 8rem !important;
  }
  .pr-xxl-9 {
    padding-right: 9rem !important;
  }
  .pr-xxl-10 {
    padding-right: 10rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0rem !important;
  }
  .pb-xxl-1 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 7rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 9rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 10rem !important;
  }
  .pl-xxl-0 {
    padding-left: 0rem !important;
  }
  .pl-xxl-1 {
    padding-left: 1rem !important;
  }
  .pl-xxl-2 {
    padding-left: 2rem !important;
  }
  .pl-xxl-3 {
    padding-left: 3rem !important;
  }
  .pl-xxl-4 {
    padding-left: 4rem !important;
  }
  .pl-xxl-5 {
    padding-left: 5rem !important;
  }
  .pl-xxl-6 {
    padding-left: 6rem !important;
  }
  .pl-xxl-7 {
    padding-left: 7rem !important;
  }
  .pl-xxl-8 {
    padding-left: 8rem !important;
  }
  .pl-xxl-9 {
    padding-left: 9rem !important;
  }
  .pl-xxl-10 {
    padding-left: 10rem !important;
  }
  .mx-xxl-0 {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .my-xxl-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .m-xxl-0 {
    margin: 0rem !important;
  }
  .mx-xxl-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .my-xxl-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .m-xxl-1 {
    margin: 1rem !important;
  }
  .mx-xxl-2 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .my-xxl-2 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .m-xxl-2 {
    margin: 2rem !important;
  }
  .mx-xxl-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .my-xxl-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .m-xxl-3 {
    margin: 3rem !important;
  }
  .mx-xxl-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }
  .my-xxl-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .m-xxl-4 {
    margin: 4rem !important;
  }
  .mx-xxl-5 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .my-xxl-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .m-xxl-5 {
    margin: 5rem !important;
  }
  .mx-xxl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .my-xxl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .m-xxl-6 {
    margin: 6rem !important;
  }
  .mx-xxl-7 {
    margin-right: 7rem !important;
    margin-left: 7rem !important;
  }
  .my-xxl-7 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }
  .m-xxl-7 {
    margin: 7rem !important;
  }
  .mx-xxl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .my-xxl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .m-xxl-8 {
    margin: 8rem !important;
  }
  .mx-xxl-9 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .my-xxl-9 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .m-xxl-9 {
    margin: 9rem !important;
  }
  .mx-xxl-10 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .my-xxl-10 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .m-xxl-10 {
    margin: 10rem !important;
  }
  .mt-xxl-0 {
    margin-top: 0rem !important;
  }
  .mt-xxl-1 {
    margin-top: 1rem !important;
  }
  .mt-xxl-2 {
    margin-top: 2rem !important;
  }
  .mt-xxl-3 {
    margin-top: 3rem !important;
  }
  .mt-xxl-4 {
    margin-top: 4rem !important;
  }
  .mt-xxl-5 {
    margin-top: 5rem !important;
  }
  .mt-xxl-6 {
    margin-top: 6rem !important;
  }
  .mt-xxl-7 {
    margin-top: 7rem !important;
  }
  .mt-xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxl-9 {
    margin-top: 9rem !important;
  }
  .mt-xxl-10 {
    margin-top: 10rem !important;
  }
  .mr-xxl-0 {
    margin-right: 0rem !important;
  }
  .mr-xxl-1 {
    margin-right: 1rem !important;
  }
  .mr-xxl-2 {
    margin-right: 2rem !important;
  }
  .mr-xxl-3 {
    margin-right: 3rem !important;
  }
  .mr-xxl-4 {
    margin-right: 4rem !important;
  }
  .mr-xxl-5 {
    margin-right: 5rem !important;
  }
  .mr-xxl-6 {
    margin-right: 6rem !important;
  }
  .mr-xxl-7 {
    margin-right: 7rem !important;
  }
  .mr-xxl-8 {
    margin-right: 8rem !important;
  }
  .mr-xxl-9 {
    margin-right: 9rem !important;
  }
  .mr-xxl-10 {
    margin-right: 10rem !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0rem !important;
  }
  .mb-xxl-1 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 10rem !important;
  }
  .ml-xxl-0 {
    margin-left: 0rem !important;
  }
  .ml-xxl-1 {
    margin-left: 1rem !important;
  }
  .ml-xxl-2 {
    margin-left: 2rem !important;
  }
  .ml-xxl-3 {
    margin-left: 3rem !important;
  }
  .ml-xxl-4 {
    margin-left: 4rem !important;
  }
  .ml-xxl-5 {
    margin-left: 5rem !important;
  }
  .ml-xxl-6 {
    margin-left: 6rem !important;
  }
  .ml-xxl-7 {
    margin-left: 7rem !important;
  }
  .ml-xxl-8 {
    margin-left: 8rem !important;
  }
  .ml-xxl-9 {
    margin-left: 9rem !important;
  }
  .ml-xxl-10 {
    margin-left: 10rem !important;
  }
}
@media (min-width: 0px) {
  .order-0 {
    order: 0;
  }
  .z-index-0 {
    z-index: 0;
  }
  .order-1 {
    order: 1;
  }
  .z-index-1 {
    z-index: 1;
  }
  .order-2 {
    order: 2;
  }
  .z-index-2 {
    z-index: 2;
  }
  .order-3 {
    order: 3;
  }
  .z-index-3 {
    z-index: 3;
  }
  .order-4 {
    order: 4;
  }
  .z-index-4 {
    z-index: 4;
  }
  .order-5 {
    order: 5;
  }
  .z-index-5 {
    z-index: 5;
  }
  .order-6 {
    order: 6;
  }
  .z-index-6 {
    z-index: 6;
  }
  .order-7 {
    order: 7;
  }
  .z-index-7 {
    z-index: 7;
  }
  .order-8 {
    order: 8;
  }
  .z-index-8 {
    z-index: 8;
  }
  .order-9 {
    order: 9;
  }
  .z-index-9 {
    z-index: 9;
  }
  .order-10 {
    order: 10;
  }
  .z-index-10 {
    z-index: 10;
  }
}
@media (min-width: 350px) {
  .order-xs-0 {
    order: 0;
  }
  .z-index-xs-0 {
    z-index: 0;
  }
  .order-xs-1 {
    order: 1;
  }
  .z-index-xs-1 {
    z-index: 1;
  }
  .order-xs-2 {
    order: 2;
  }
  .z-index-xs-2 {
    z-index: 2;
  }
  .order-xs-3 {
    order: 3;
  }
  .z-index-xs-3 {
    z-index: 3;
  }
  .order-xs-4 {
    order: 4;
  }
  .z-index-xs-4 {
    z-index: 4;
  }
  .order-xs-5 {
    order: 5;
  }
  .z-index-xs-5 {
    z-index: 5;
  }
  .order-xs-6 {
    order: 6;
  }
  .z-index-xs-6 {
    z-index: 6;
  }
  .order-xs-7 {
    order: 7;
  }
  .z-index-xs-7 {
    z-index: 7;
  }
  .order-xs-8 {
    order: 8;
  }
  .z-index-xs-8 {
    z-index: 8;
  }
  .order-xs-9 {
    order: 9;
  }
  .z-index-xs-9 {
    z-index: 9;
  }
  .order-xs-10 {
    order: 10;
  }
  .z-index-xs-10 {
    z-index: 10;
  }
}
@media (min-width: 512px) {
  .order-sm-0 {
    order: 0;
  }
  .z-index-sm-0 {
    z-index: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .z-index-sm-1 {
    z-index: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .z-index-sm-2 {
    z-index: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .z-index-sm-3 {
    z-index: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .z-index-sm-4 {
    z-index: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .z-index-sm-5 {
    z-index: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .z-index-sm-6 {
    z-index: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .z-index-sm-7 {
    z-index: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .z-index-sm-8 {
    z-index: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .z-index-sm-9 {
    z-index: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .z-index-sm-10 {
    z-index: 10;
  }
}
@media (min-width: 768px) {
  .order-md-0 {
    order: 0;
  }
  .z-index-md-0 {
    z-index: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .z-index-md-1 {
    z-index: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .z-index-md-2 {
    z-index: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .z-index-md-3 {
    z-index: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .z-index-md-4 {
    z-index: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .z-index-md-5 {
    z-index: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .z-index-md-6 {
    z-index: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .z-index-md-7 {
    z-index: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .z-index-md-8 {
    z-index: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .z-index-md-9 {
    z-index: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .z-index-md-10 {
    z-index: 10;
  }
}
@media (min-width: 1024px) {
  .order-lg-0 {
    order: 0;
  }
  .z-index-lg-0 {
    z-index: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .z-index-lg-1 {
    z-index: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .z-index-lg-2 {
    z-index: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .z-index-lg-3 {
    z-index: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .z-index-lg-4 {
    z-index: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .z-index-lg-5 {
    z-index: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .z-index-lg-6 {
    z-index: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .z-index-lg-7 {
    z-index: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .z-index-lg-8 {
    z-index: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .z-index-lg-9 {
    z-index: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .z-index-lg-10 {
    z-index: 10;
  }
}
@media (min-width: 1280px) {
  .order-xl-0 {
    order: 0;
  }
  .z-index-xl-0 {
    z-index: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .z-index-xl-1 {
    z-index: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .z-index-xl-2 {
    z-index: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .z-index-xl-3 {
    z-index: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .z-index-xl-4 {
    z-index: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .z-index-xl-5 {
    z-index: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .z-index-xl-6 {
    z-index: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .z-index-xl-7 {
    z-index: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .z-index-xl-8 {
    z-index: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .z-index-xl-9 {
    z-index: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .z-index-xl-10 {
    z-index: 10;
  }
}
@media (min-width: 1536px) {
  .order-xxl-0 {
    order: 0;
  }
  .z-index-xxl-0 {
    z-index: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .z-index-xxl-1 {
    z-index: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .z-index-xxl-2 {
    z-index: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .z-index-xxl-3 {
    z-index: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .z-index-xxl-4 {
    z-index: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .z-index-xxl-5 {
    z-index: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .z-index-xxl-6 {
    z-index: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .z-index-xxl-7 {
    z-index: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .z-index-xxl-8 {
    z-index: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .z-index-xxl-9 {
    z-index: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .z-index-xxl-10 {
    z-index: 10;
  }
}
@media (min-width: 0px) {
  .gap-0 {
    gap: 0rem !important;
  }
  .row-gap-0 {
    row-gap: 0rem !important;
  }
  .col-gap-0 {
    column-gap: 0rem !important;
  }
  .gap-1 {
    gap: 1rem !important;
  }
  .row-gap-1 {
    row-gap: 1rem !important;
  }
  .col-gap-1 {
    column-gap: 1rem !important;
  }
  .gap-2 {
    gap: 2rem !important;
  }
  .row-gap-2 {
    row-gap: 2rem !important;
  }
  .col-gap-2 {
    column-gap: 2rem !important;
  }
  .gap-3 {
    gap: 3rem !important;
  }
  .row-gap-3 {
    row-gap: 3rem !important;
  }
  .col-gap-3 {
    column-gap: 3rem !important;
  }
  .gap-4 {
    gap: 4rem !important;
  }
  .row-gap-4 {
    row-gap: 4rem !important;
  }
  .col-gap-4 {
    column-gap: 4rem !important;
  }
  .gap-5 {
    gap: 5rem !important;
  }
  .row-gap-5 {
    row-gap: 5rem !important;
  }
  .col-gap-5 {
    column-gap: 5rem !important;
  }
  .gap-6 {
    gap: 6rem !important;
  }
  .row-gap-6 {
    row-gap: 6rem !important;
  }
  .col-gap-6 {
    column-gap: 6rem !important;
  }
  .gap-7 {
    gap: 7rem !important;
  }
  .row-gap-7 {
    row-gap: 7rem !important;
  }
  .col-gap-7 {
    column-gap: 7rem !important;
  }
  .gap-8 {
    gap: 8rem !important;
  }
  .row-gap-8 {
    row-gap: 8rem !important;
  }
  .col-gap-8 {
    column-gap: 8rem !important;
  }
  .gap-9 {
    gap: 9rem !important;
  }
  .row-gap-9 {
    row-gap: 9rem !important;
  }
  .col-gap-9 {
    column-gap: 9rem !important;
  }
  .gap-10 {
    gap: 10rem !important;
  }
  .row-gap-10 {
    row-gap: 10rem !important;
  }
  .col-gap-10 {
    column-gap: 10rem !important;
  }
}
@media (min-width: 350px) {
  .gap-xs-0 {
    gap: 0rem !important;
  }
  .row-gap-xs-0 {
    row-gap: 0rem !important;
  }
  .col-gap-xs-0 {
    column-gap: 0rem !important;
  }
  .gap-xs-1 {
    gap: 1rem !important;
  }
  .row-gap-xs-1 {
    row-gap: 1rem !important;
  }
  .col-gap-xs-1 {
    column-gap: 1rem !important;
  }
  .gap-xs-2 {
    gap: 2rem !important;
  }
  .row-gap-xs-2 {
    row-gap: 2rem !important;
  }
  .col-gap-xs-2 {
    column-gap: 2rem !important;
  }
  .gap-xs-3 {
    gap: 3rem !important;
  }
  .row-gap-xs-3 {
    row-gap: 3rem !important;
  }
  .col-gap-xs-3 {
    column-gap: 3rem !important;
  }
  .gap-xs-4 {
    gap: 4rem !important;
  }
  .row-gap-xs-4 {
    row-gap: 4rem !important;
  }
  .col-gap-xs-4 {
    column-gap: 4rem !important;
  }
  .gap-xs-5 {
    gap: 5rem !important;
  }
  .row-gap-xs-5 {
    row-gap: 5rem !important;
  }
  .col-gap-xs-5 {
    column-gap: 5rem !important;
  }
  .gap-xs-6 {
    gap: 6rem !important;
  }
  .row-gap-xs-6 {
    row-gap: 6rem !important;
  }
  .col-gap-xs-6 {
    column-gap: 6rem !important;
  }
  .gap-xs-7 {
    gap: 7rem !important;
  }
  .row-gap-xs-7 {
    row-gap: 7rem !important;
  }
  .col-gap-xs-7 {
    column-gap: 7rem !important;
  }
  .gap-xs-8 {
    gap: 8rem !important;
  }
  .row-gap-xs-8 {
    row-gap: 8rem !important;
  }
  .col-gap-xs-8 {
    column-gap: 8rem !important;
  }
  .gap-xs-9 {
    gap: 9rem !important;
  }
  .row-gap-xs-9 {
    row-gap: 9rem !important;
  }
  .col-gap-xs-9 {
    column-gap: 9rem !important;
  }
  .gap-xs-10 {
    gap: 10rem !important;
  }
  .row-gap-xs-10 {
    row-gap: 10rem !important;
  }
  .col-gap-xs-10 {
    column-gap: 10rem !important;
  }
}
@media (min-width: 512px) {
  .gap-sm-0 {
    gap: 0rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0rem !important;
  }
  .col-gap-sm-0 {
    column-gap: 0rem !important;
  }
  .gap-sm-1 {
    gap: 1rem !important;
  }
  .row-gap-sm-1 {
    row-gap: 1rem !important;
  }
  .col-gap-sm-1 {
    column-gap: 1rem !important;
  }
  .gap-sm-2 {
    gap: 2rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 2rem !important;
  }
  .col-gap-sm-2 {
    column-gap: 2rem !important;
  }
  .gap-sm-3 {
    gap: 3rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 3rem !important;
  }
  .col-gap-sm-3 {
    column-gap: 3rem !important;
  }
  .gap-sm-4 {
    gap: 4rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 4rem !important;
  }
  .col-gap-sm-4 {
    column-gap: 4rem !important;
  }
  .gap-sm-5 {
    gap: 5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 5rem !important;
  }
  .col-gap-sm-5 {
    column-gap: 5rem !important;
  }
  .gap-sm-6 {
    gap: 6rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 6rem !important;
  }
  .col-gap-sm-6 {
    column-gap: 6rem !important;
  }
  .gap-sm-7 {
    gap: 7rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 7rem !important;
  }
  .col-gap-sm-7 {
    column-gap: 7rem !important;
  }
  .gap-sm-8 {
    gap: 8rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 8rem !important;
  }
  .col-gap-sm-8 {
    column-gap: 8rem !important;
  }
  .gap-sm-9 {
    gap: 9rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 9rem !important;
  }
  .col-gap-sm-9 {
    column-gap: 9rem !important;
  }
  .gap-sm-10 {
    gap: 10rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 10rem !important;
  }
  .col-gap-sm-10 {
    column-gap: 10rem !important;
  }
}
@media (min-width: 768px) {
  .gap-md-0 {
    gap: 0rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0rem !important;
  }
  .col-gap-md-0 {
    column-gap: 0rem !important;
  }
  .gap-md-1 {
    gap: 1rem !important;
  }
  .row-gap-md-1 {
    row-gap: 1rem !important;
  }
  .col-gap-md-1 {
    column-gap: 1rem !important;
  }
  .gap-md-2 {
    gap: 2rem !important;
  }
  .row-gap-md-2 {
    row-gap: 2rem !important;
  }
  .col-gap-md-2 {
    column-gap: 2rem !important;
  }
  .gap-md-3 {
    gap: 3rem !important;
  }
  .row-gap-md-3 {
    row-gap: 3rem !important;
  }
  .col-gap-md-3 {
    column-gap: 3rem !important;
  }
  .gap-md-4 {
    gap: 4rem !important;
  }
  .row-gap-md-4 {
    row-gap: 4rem !important;
  }
  .col-gap-md-4 {
    column-gap: 4rem !important;
  }
  .gap-md-5 {
    gap: 5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 5rem !important;
  }
  .col-gap-md-5 {
    column-gap: 5rem !important;
  }
  .gap-md-6 {
    gap: 6rem !important;
  }
  .row-gap-md-6 {
    row-gap: 6rem !important;
  }
  .col-gap-md-6 {
    column-gap: 6rem !important;
  }
  .gap-md-7 {
    gap: 7rem !important;
  }
  .row-gap-md-7 {
    row-gap: 7rem !important;
  }
  .col-gap-md-7 {
    column-gap: 7rem !important;
  }
  .gap-md-8 {
    gap: 8rem !important;
  }
  .row-gap-md-8 {
    row-gap: 8rem !important;
  }
  .col-gap-md-8 {
    column-gap: 8rem !important;
  }
  .gap-md-9 {
    gap: 9rem !important;
  }
  .row-gap-md-9 {
    row-gap: 9rem !important;
  }
  .col-gap-md-9 {
    column-gap: 9rem !important;
  }
  .gap-md-10 {
    gap: 10rem !important;
  }
  .row-gap-md-10 {
    row-gap: 10rem !important;
  }
  .col-gap-md-10 {
    column-gap: 10rem !important;
  }
}
@media (min-width: 1024px) {
  .gap-lg-0 {
    gap: 0rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0rem !important;
  }
  .col-gap-lg-0 {
    column-gap: 0rem !important;
  }
  .gap-lg-1 {
    gap: 1rem !important;
  }
  .row-gap-lg-1 {
    row-gap: 1rem !important;
  }
  .col-gap-lg-1 {
    column-gap: 1rem !important;
  }
  .gap-lg-2 {
    gap: 2rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 2rem !important;
  }
  .col-gap-lg-2 {
    column-gap: 2rem !important;
  }
  .gap-lg-3 {
    gap: 3rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 3rem !important;
  }
  .col-gap-lg-3 {
    column-gap: 3rem !important;
  }
  .gap-lg-4 {
    gap: 4rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 4rem !important;
  }
  .col-gap-lg-4 {
    column-gap: 4rem !important;
  }
  .gap-lg-5 {
    gap: 5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 5rem !important;
  }
  .col-gap-lg-5 {
    column-gap: 5rem !important;
  }
  .gap-lg-6 {
    gap: 6rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 6rem !important;
  }
  .col-gap-lg-6 {
    column-gap: 6rem !important;
  }
  .gap-lg-7 {
    gap: 7rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 7rem !important;
  }
  .col-gap-lg-7 {
    column-gap: 7rem !important;
  }
  .gap-lg-8 {
    gap: 8rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 8rem !important;
  }
  .col-gap-lg-8 {
    column-gap: 8rem !important;
  }
  .gap-lg-9 {
    gap: 9rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 9rem !important;
  }
  .col-gap-lg-9 {
    column-gap: 9rem !important;
  }
  .gap-lg-10 {
    gap: 10rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 10rem !important;
  }
  .col-gap-lg-10 {
    column-gap: 10rem !important;
  }
}
@media (min-width: 1280px) {
  .gap-xl-0 {
    gap: 0rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0rem !important;
  }
  .col-gap-xl-0 {
    column-gap: 0rem !important;
  }
  .gap-xl-1 {
    gap: 1rem !important;
  }
  .row-gap-xl-1 {
    row-gap: 1rem !important;
  }
  .col-gap-xl-1 {
    column-gap: 1rem !important;
  }
  .gap-xl-2 {
    gap: 2rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 2rem !important;
  }
  .col-gap-xl-2 {
    column-gap: 2rem !important;
  }
  .gap-xl-3 {
    gap: 3rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 3rem !important;
  }
  .col-gap-xl-3 {
    column-gap: 3rem !important;
  }
  .gap-xl-4 {
    gap: 4rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 4rem !important;
  }
  .col-gap-xl-4 {
    column-gap: 4rem !important;
  }
  .gap-xl-5 {
    gap: 5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 5rem !important;
  }
  .col-gap-xl-5 {
    column-gap: 5rem !important;
  }
  .gap-xl-6 {
    gap: 6rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 6rem !important;
  }
  .col-gap-xl-6 {
    column-gap: 6rem !important;
  }
  .gap-xl-7 {
    gap: 7rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 7rem !important;
  }
  .col-gap-xl-7 {
    column-gap: 7rem !important;
  }
  .gap-xl-8 {
    gap: 8rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 8rem !important;
  }
  .col-gap-xl-8 {
    column-gap: 8rem !important;
  }
  .gap-xl-9 {
    gap: 9rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 9rem !important;
  }
  .col-gap-xl-9 {
    column-gap: 9rem !important;
  }
  .gap-xl-10 {
    gap: 10rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 10rem !important;
  }
  .col-gap-xl-10 {
    column-gap: 10rem !important;
  }
}
@media (min-width: 1536px) {
  .gap-xxl-0 {
    gap: 0rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0rem !important;
  }
  .col-gap-xxl-0 {
    column-gap: 0rem !important;
  }
  .gap-xxl-1 {
    gap: 1rem !important;
  }
  .row-gap-xxl-1 {
    row-gap: 1rem !important;
  }
  .col-gap-xxl-1 {
    column-gap: 1rem !important;
  }
  .gap-xxl-2 {
    gap: 2rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 2rem !important;
  }
  .col-gap-xxl-2 {
    column-gap: 2rem !important;
  }
  .gap-xxl-3 {
    gap: 3rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 3rem !important;
  }
  .col-gap-xxl-3 {
    column-gap: 3rem !important;
  }
  .gap-xxl-4 {
    gap: 4rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 4rem !important;
  }
  .col-gap-xxl-4 {
    column-gap: 4rem !important;
  }
  .gap-xxl-5 {
    gap: 5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 5rem !important;
  }
  .col-gap-xxl-5 {
    column-gap: 5rem !important;
  }
  .gap-xxl-6 {
    gap: 6rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 6rem !important;
  }
  .col-gap-xxl-6 {
    column-gap: 6rem !important;
  }
  .gap-xxl-7 {
    gap: 7rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 7rem !important;
  }
  .col-gap-xxl-7 {
    column-gap: 7rem !important;
  }
  .gap-xxl-8 {
    gap: 8rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 8rem !important;
  }
  .col-gap-xxl-8 {
    column-gap: 8rem !important;
  }
  .gap-xxl-9 {
    gap: 9rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 9rem !important;
  }
  .col-gap-xxl-9 {
    column-gap: 9rem !important;
  }
  .gap-xxl-10 {
    gap: 10rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 10rem !important;
  }
  .col-gap-xxl-10 {
    column-gap: 10rem !important;
  }
}
/* no text highlight */
img,
video,
button,
button-outline,
p.badge,
.bg .badge.plink,
form.badge,
ol.badge,
ul.badge,
p.badge-outline,
.bg .badge-outline.plink,
form.badge-outline,
ol.badge-outline,
ul.badge-outline {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
}

/* https://icons.getbootstrap.com/ */
/* https://gist.github.com/Albert221/753d7f8955eeb6f5e50486fce048e39f */
@font-face {
  font-family: "JetBrains Mono";
  src: url("/public/fonts/jetbrains-web/JetBrainsMono-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "JetBrains Mono";
  src: url("/public/fonts/jetbrains-web/JetBrainsMono-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "JetBrains Mono";
  src: url("/public/fonts/jetbrains-web/JetBrainsMono-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "JetBrains Mono";
  src: url("/public/fonts/jetbrains-web/JetBrainsMono-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "JetBrains Mono";
  src: url("/public/fonts/jetbrains-web/JetBrainsMono-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "JetBrains Mono";
  src: url("/public/fonts/jetbrains-web/JetBrainsMono-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avara";
  src: url("/public/fonts/avara-web/Avara-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avara";
  src: url("/public/fonts/avara-web/Avara-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
h5 {
  line-height: 130%;
  letter-spacing: 0.025rem;
  filter: saturate(1.25);
  transition: font-size 50ms ease-in-out, margin-bottom 200ms ease-in-out;
}

h5::selection {
  background: rgba(var(--accent), 50%);
}

h4 {
  font-family: "jetbrains mono";
  font-weight: 600;
  font-size: clamp(1.125rem, 0.9951780415rem + 0.5934718101vw, 1.375rem);
  line-height: 130%;
  letter-spacing: 0.025rem;
  filter: saturate(1.25);
  transition: font-size 50ms ease-in-out, margin-bottom 200ms ease-in-out;
}

h4::selection {
  background: rgba(var(--accent), 50%);
}

h3 {
  font-family: "avara";
  font-weight: 700;
  font-size: clamp(1.5rem, 1.3701780415rem + 0.5934718101vw, 1.75rem);
  line-height: 130%;
  letter-spacing: 0.025rem;
  filter: saturate(1.25);
  transition: font-size 50ms ease-in-out, margin-bottom 200ms ease-in-out;
}

h3::selection {
  background: rgba(var(--accent), 50%);
}

h2 {
  font-family: "avara";
  font-weight: 700;
  font-size: clamp(1.75rem, 1.4903560831rem + 1.1869436202vw, 2.25rem);
  line-height: 130%;
  letter-spacing: 0.025rem;
  filter: saturate(1.25);
  transition: font-size 50ms ease-in-out, margin-bottom 200ms ease-in-out;
}

h2::selection {
  background: rgba(var(--accent), 50%);
}

h1 {
  font-family: "avara";
  font-weight: 900;
  font-size: clamp(2rem, 1.4807121662rem + 2.3738872404vw, 3rem);
  line-height: 130%;
  letter-spacing: 0.025rem;
  filter: saturate(1.25);
  transition: font-size 50ms ease-in-out, margin-bottom 200ms ease-in-out;
}

h1::selection {
  background: rgba(var(--accent), 50%);
}

h1 + p, .bg h1 + .plink, h1 + form, h1 + ol,
h1 + ul {
  margin-top: -12px;
}

h2 + p, .bg h2 + .plink, h2 + form, h2 + ol,
h2 + ul {
  margin-top: -16px;
}

h3 + p, .bg h3 + .plink, h3 + form, h3 + ol,
h3 + ul {
  margin-top: -20px;
}

h4 + p, .bg h4 + .plink, h4 + form, h4 + ol,
h4 + ul {
  margin-top: -18px;
}

p, .bg .plink, form, ol,
ul {
  font-size: clamp(1rem, 0.9350890208rem + 0.296735905vw, 1.125rem);
  font-family: "jetbrains mono";
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.0375rem;
  padding: 0rem;
  margin: 0rem;
}
p + p, .bg .plink + p, .bg p + .plink, .bg .plink + .plink, form + p, .bg form + .plink, p + form, .bg .plink + form, form + form, ol + p, .bg ol + .plink, ol + form,
ul + p,
.bg ul + .plink,
ul + form, p + ol, .bg .plink + ol, form + ol, ol + ol,
ul + ol,
p + ul,
.bg .plink + ul,
form + ul,
ol + ul,
ul + ul {
  margin-top: -1rem;
}
p + p.note, .bg p + p.notelink, .bg .plink + p.note, .bg .plink + p.notelink, .bg p + .note.plink, .bg p + .plink.notelink, .bg .plink + .note.plink, .bg .plink + .plink.notelink, form + p.note, .bg form + p.notelink, .bg form + .note.plink, .bg form + .plink.notelink, p + form.note, .bg p + form.notelink, .bg .plink + form.note, .bg .plink + form.notelink, form + form.note, .bg form + form.notelink, ol + p.note, .bg ol + p.notelink, .bg ol + .note.plink, .bg ol + .plink.notelink, ol + form.note, .bg ol + form.notelink,
ul + p.note,
.bg ul + p.notelink,
.bg ul + .note.plink,
.bg ul + .plink.notelink,
ul + form.note,
.bg ul + form.notelink, p + ol.note, .bg p + ol.notelink, .bg .plink + ol.note, .bg .plink + ol.notelink, form + ol.note, .bg form + ol.notelink, ol + ol.note, .bg ol + ol.notelink,
ul + ol.note,
.bg ul + ol.notelink,
p + ul.note,
.bg p + ul.notelink,
.bg .plink + ul.note,
.bg .plink + ul.notelink,
form + ul.note,
.bg form + ul.notelink,
ol + ul.note,
.bg ol + ul.notelink,
ul + ul.note,
.bg ul + ul.notelink {
  margin-top: -0.5rem;
}
p::selection, .bg .plink::selection, form::selection, ol::selection,
ul::selection {
  background: rgba(var(--accent), 50%);
}

p.small, .bg .small.plink, form.small, ol.small,
ul.small {
  font-size: 1rem;
  line-height: 140%;
}

.note, .bg .notelink {
  font-family: "jetbrains mono";
  font-style: italic;
  font-weight: 200;
  margin-left: 0.125rem;
  opacity: 80%;
}

ol,
ul {
  font-size: clamp(1.25rem, 1.25rem + 1.5vw, 1.4rem);
  font-family: "jetbrains mono";
  font-weight: 300;
  line-height: 165%;
  margin: 2rem 0rem 0rem;
}
ol li + li,
ul li + li {
  margin-top: 0.85rem;
}
ol + p, .bg ol + .plink, ol + form, ol + ol,
ol + ul,
ul + p,
.bg ul + .plink,
ul + form,
ul + ol,
ul + ul {
  margin-top: 2rem;
}

ol {
  margin-left: 1rem;
  counter-reset: list-counter;
}
ol li + li {
  margin-top: 1.5rem;
}
ol li {
  counter-increment: list-counter;
}
ol li::marker {
  font-weight: bold;
}

a {
  color: inherit;
  text-decoration: none;
}

.color-set-toggles-bar.color-set-nav {
  display: grid;
  gap: 0.25rem;
  height: 100%;
}

.color-set-nav > .color-set-toggle {
  aspect-ratio: 1/1;
  width: 100%;
  max-width: 55px;
  padding: 1.125rem;
  border-radius: 2rem;
  border: 8px solid rgba(var(--blk), 100%);
  opacity: 0;
  scale: 0.8;
  transform: translateY(100px);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, scale 200ms ease-in-out, false, false;
}
.color-set-nav > .color-set-toggle:hover {
  scale: 0.9 !important;
  filter: saturate(1.25);
}

.color-set-toggles-bar.color-set-home {
  display: flex;
  flex-flow: row wrap;
  place-items: center;
  place-content: center;
  gap: 0.375rem;
  width: 100%;
}
@media (min-width: 350px) {
  .color-set-toggles-bar.color-set-home {
    width: 260px;
  }
}
@media (min-width: 512px) {
  .color-set-toggles-bar.color-set-home {
    width: 100%;
  }
}

.color-set-home > .color-set-toggle {
  aspect-ratio: 1/1;
  flex: 1 1 14.2857142857%;
  max-width: 55px;
  padding: 1.125rem;
  border-radius: 2rem;
  border: 8px solid rgba(var(--bg), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, scale 200ms ease-in-out, false, false;
}
.color-set-home > .color-set-toggle:hover {
  scale: 0.9 !important;
  filter: saturate(1.25);
}

.projects-return {
  z-index: 9000;
  position: fixed;
  font-size: clamp(1.25rem, 4.5vw, 1.5rem) !important;
  top: 0.5rem;
  left: 0.75rem;
  padding: 0.65rem 1.3rem 0.75rem 1.25rem !important;
}
@media (min-width: 768px) {
  .projects-return {
    top: 1rem;
    left: 1rem;
  }
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 450px));
  grid-template-rows: repeat(auto-fit, auto);
  gap: clamp(2rem, 1.2859792285rem + 3.2640949555vw, 3.375rem);
  place-content: center;
  min-height: 100vh;
  padding: clamp(3.375rem, 2.0118694362rem + 6.2314540059vw, 6rem) clamp(1rem, -0.0385756677rem + 4.7477744807vw, 3rem);
}

.project-cover-bg {
  pointer-events: none;
  z-index: -100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-size: cover;
  transition: opacity 200ms ease-in-out;
}

.gallery {
  place-content: space-between;
  place-items: stretch;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
@media (min-width: 768px) {
  .gallery {
    grid-template-columns: [left-button] 40px [slide] 1fr [right-button] 40px;
    grid-template-areas: none;
    gap: 0;
    place-items: center;
    margin-left: 0;
    margin-right: 0;
  }
}

.gallery-button {
  z-index: 8000;
  font-size: 1.5rem !important;
  padding: 0.125rem 1.5rem 0.25rem !important;
  border-radius: 0.5rem !important;
}
.gallery-button:hover {
  background-color: rgba(var(--accent), 100%) !important;
}

.slide {
  display: none;
  grid-column: slide;
  grid-row: 1;
  width: 100%;
}
.slide.active {
  display: block;
}
.slide > img {
  height: 100%;
  width: 100%;
  max-height: 80vh;
}

.nav-placement-wrap {
  pointer-events: none;
  z-index: 9999;
  position: fixed;
  bottom: 0rem;
  right: 0rem;
  display: flex;
  place-content: end;
  width: 100%;
  max-width: 100dvw;
  max-height: 100dvh;
  padding: 0.5rem;
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, translate 200ms ease-in-out, false;
}
@media (min-width: 768px) {
  .nav-placement-wrap {
    padding: 1rem;
  }
}

.menu-container {
  pointer-events: auto;
  z-index: 9999;
  display: grid;
  grid-template-columns: 55px 1fr;
  gap: 1rem;
  width: 155px;
  max-width: 550px;
  height: 100%;
  max-height: 550px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgba(var(--blk), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, translate 200ms ease-in-out, false;
}

.colors-mask {
  overflow: hidden;
  position: relative;
  height: 0px;
  padding-bottom: 0rem;
}

.colors-container {
  pointer-events: auto;
  display: grid;
  grid-template-rows: 0fr 54px;
  justify-items: stretch;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.nav-mask {
  overflow: hidden;
  position: relative;
  height: 0px;
  padding-bottom: 0rem;
}

.nav-container {
  pointer-events: auto;
  display: grid;
  grid-template-rows: 0fr 54px;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.nav-link-container {
  pointer-events: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(var(--bg), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, translate 200ms ease-in-out, false;
  transform-origin: bottom center;
}
@media (min-width: 512px) {
  .nav-link-container {
    grid-template-columns: 1fr 1fr;
  }
}

.theme-toggle {
  font-family: "jetbrains mono";
  font-size: 1rem;
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  padding: 0.625rem 1rem 0.75rem;
  border-radius: 0.325rem;
  border: 2px solid rgba(var(--text), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, translate 200ms ease-in-out, false;
  margin-left: 0rem !important;
}
.theme-toggle:hover {
  color: rgba(var(--text), 100%);
  background-color: rgba(var(--bg), 100%);
  border: 2px solid rgba(var(--accent), 100%);
  box-shadow: 4px 4px 0px 0px rgba(var(--accent), 100%);
  translate: -4px -4px;
}

.nav-toggle {
  font-family: "jetbrains mono";
  font-size: 1rem;
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  padding: 0.625rem 1rem 0.75rem;
  border-radius: 0.325rem;
  border: 2px solid rgba(var(--text), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, translate 200ms ease-in-out, false;
  justify-self: end;
  z-index: 10;
}
.nav-toggle:hover {
  color: rgba(var(--text), 100%);
  background-color: rgba(var(--bg), 100%);
  border: 2px solid rgba(var(--accent), 100%);
  box-shadow: 4px 4px 0px 0px rgba(var(--accent), 100%);
  translate: -4px -4px;
}

.nav-link {
  display: flex;
  place-items: center;
  place-content: center;
  font-family: "jetbrains mono";
  font-size: 1.125rem;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-radius: 0.325rem;
  border: 2px solid rgba(var(--blk), 100%);
  opacity: 1;
  color: rgba(var(--text), 100%);
  background-color: rgba(var(--accent), 15%);
  box-shadow: 4px 4px 0px rgba(var(--blk), 100%);
  scale: 0.8;
  translate: 0;
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, translate 200ms ease-in-out, false;
}
.nav-link:hover {
  color: rgba(var(--text), 100%);
  background-color: rgba(var(--accent), 50%);
  translate: -2px -2px;
}
.nav-link.active {
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  border: 2px solid rgba(var(--blk), 100%);
}
.nav-link.active:hover {
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--accent), 100%);
  border: 2px solid rgba(var(--blk), 100%);
}

.devices-container {
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  place-items: center;
  overflow-x: clip;
  width: 100%;
}
@media (min-width: 768px) {
  .devices-container {
    overflow-x: visible;
  }
}

.device-container {
  display: flex;
  place-content: center;
  grid-column: 1;
  grid-row: 1;
  padding: 0rem;
  width: 100%;
}
@media (min-width: 1024px) {
  .device-container {
    padding: 0rem;
  }
}

.device-toggle-button {
  font-size: 1rem !important;
  margin: 0 !important;
  padding: 0.5rem 1rem !important;
  box-shadow: none !important;
}
.device-toggle-button.right {
  border-radius: 0rem 0.5rem 0.5rem 0rem !important;
}
.device-toggle-button.left {
  border-radius: 0.5rem 0rem 0rem 0.5rem !important;
}
.device-toggle-button:hover {
  translate: 0 !important;
}
.device-toggle-button:active {
  translate: 0 !important;
}

.device-page-toggle {
  font-size: 1rem !important;
  padding: 0.4375rem 1rem 0.5rem !important;
  margin: 0rem !important;
  border-radius: 0.325rem !important;
  box-shadow: none !important;
}
.device-page-toggle:hover {
  translate: -2px -2px !important;
}

.page-toggles-container {
  gap: 0.5rem;
}

.palm-pre {
  z-index: 100;
  display: flex;
  place-items: center;
  place-content: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  min-width: 250px;
  scale: 0;
}

.palm-pre-top {
  z-index: 3;
  pointer-events: none;
}

.palm-pre-img-mask {
  z-index: 2;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  aspect-ratio: 638/901;
  scale: 1.055;
  translate: 1% -1.5%;
}

.palm-pre-img {
  width: 100%;
}

.palm-pre-bottom {
  z-index: 1;
  position: absolute;
}

.crt-monitor {
  z-index: 100;
  display: flex;
  place-items: center;
  place-content: center;
  position: relative;
  width: 100%;
  max-width: 1000px;
  min-width: 300px;
}

.crt-monitor-frame {
  z-index: 3;
  pointer-events: none;
}

.crt-monitor-img-mask {
  z-index: 2;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  aspect-ratio: 341/256;
  scale: 0.6075;
  translate: -0.05% -12.15%;
}

.crt-monitor-img {
  width: 100%;
}

label {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
  flex: 1 1 30%;
}

fieldset {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 100%;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  padding: 0;
}

legend {
  font-size: 1.25rem;
  font-weight: 500;
  padding-bottom: 0.75rem;
}

fieldset > label {
  flex-flow: row nowrap;
  flex: 1 1 auto;
  column-gap: 0.5rem;
  place-content: start;
  font-size: 1.125rem;
  font-weight: 300;
}

fieldset > label > input[type=text] {
  padding: 0.35rem 0.75rem 0.45rem;
}

input[type=text],
input[type=email],
textarea {
  flex: 1 1 100%;
  font-size: 1.125rem;
  font-weight: 300;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.5rem;
}

input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  background-color: none;
  margin: 0;
  display: grid;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.325rem;
  border: 2px solid rgba(var(--accent), 100%);
  transition: all 50ms ease-in-out;
}

input[type=checkbox]::before {
  content: "";
  width: 1rem;
  height: 1rem;
  scale: 0;
  box-shadow: inset 1em 1em rgba(var(--accent), 100%);
  transition: all 50ms ease-in-out;
}

input[type=checkbox]:checked::before {
  scale: 1;
}

input[type=checkbox]:checked {
  border: 2px solid rgba(var(--accent), 50%);
}

.computer-home {
  position: absolute;
  top: calc(8dvh - 10px);
  right: calc(36dvw - 100px);
  width: 200px;
  transition: scale 650ms ease-in-out, opacity 650ms ease-in-out;
  scale: 1;
  opacity: 0.65;
}
.computer-home:hover {
  opacity: 1;
  scale: 1.125 !important;
}

.bg {
  color: rgba(var(--text), 100%);
  background-color: rgba(var(--bg), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, false, false;
}
.bg .plink {
  white-space: nowrap;
  letter-spacing: -0.0625rem;
  font-weight: 300;
  text-decoration: none;
  text-shadow: 0rem 0.0625rem rgba(var(--blk), 0%);
  padding: 0rem 0.125rem 0rem 0.125rem;
  margin: 0rem -0.125rem 0rem -0.125rem;
  border-radius: 0;
  filter: saturate(1.5);
  box-shadow: inset 0 -2px 0 0 rgba(var(--accent), 60%), inset 0rem 0rem 0 0 rgba(var(--accent), 0%), inset 0rem 0rem 0 0 rgba(var(--accent), 0%), inset 0rem 0rem 0 0 rgba(var(--accent), 0%), inset 0rem 0rem 0 0 rgba(var(--accent), 0%), 0 0 1rem 0 rgba(var(--accent), 0%);
  transition: all 200ms ease-out;
}
.bg .plink:hover {
  color: rgba(var(--blk), 100%);
  padding: 0rem 0.375rem 0rem 0.375rem;
  margin-left: -0.375rem;
  margin-right: -0.375rem;
  border-radius: 0.325rem;
  box-shadow: inset 0 -0.095rem 0 0 rgba(var(--accent), 0%), inset 5rem -1rem 1rem 0 rgba(var(--accent), 5%), inset 10rem -1.5rem 1rem 0 rgba(var(--accent), 15%), inset 15rem -2rem 1rem 0 rgba(var(--accent), 25%), inset 20rem -2.5rem 1rem 0 rgba(var(--accent), 35%), 0 0 0.15rem 0 rgba(var(--accent), 0%);
}
.bg .plink:active {
  opacity: 80%;
  box-shadow: inset 0 -0.095rem 0 0 rgba(var(--accent), 0%), inset 5rem -1rem 1rem 0 rgba(var(--accent), 15%), inset 10rem -1.5rem 1rem 0 rgba(var(--accent), 15%), inset 15rem -2rem 1rem 0 rgba(var(--accent), 15%), inset 20rem -2.5rem 1rem 0 rgba(var(--accent), 15%), 0 0 0.15rem 0 rgba(var(--accent), 0%);
}
.bg .notelink {
  opacity: 1 !important;
  white-space: nowrap;
  letter-spacing: -0.0625rem;
  font-weight: 300;
  text-decoration: none;
  text-shadow: 0rem 0.0625rem rgba(var(--blk), 0%);
  padding: 0rem 0.125rem 0rem 0.125rem;
  margin: 0rem -0.125rem 0rem -0.125rem;
  border-radius: 0;
  filter: saturate(1.5);
  box-shadow: inset 0 -2px 0 0 rgba(var(--accent), 60%), inset 0rem 0rem 0 0 rgba(var(--accent), 0%), inset 0rem 0rem 0 0 rgba(var(--accent), 0%), inset 0rem 0rem 0 0 rgba(var(--accent), 0%), inset 0rem 0rem 0 0 rgba(var(--accent), 0%), 0 0 1rem 0 rgba(var(--accent), 0%);
  transition: all 200ms ease-out;
}
.bg .notelink:hover {
  color: rgba(var(--blk), 100%);
  padding: 0rem 0.375rem 0rem 0.375rem;
  margin-left: -0.375rem;
  margin-right: -0.375rem;
  border-radius: 0.325rem;
  box-shadow: inset 0 -0.095rem 0 0 rgba(var(--accent), 0%), inset 5rem -1rem 1rem 0 rgba(var(--accent), 5%), inset 10rem -1.5rem 1rem 0 rgba(var(--accent), 15%), inset 15rem -2rem 1rem 0 rgba(var(--accent), 25%), inset 20rem -2.5rem 1rem 0 rgba(var(--accent), 35%), 0 0 0.15rem 0 rgba(var(--accent), 0%);
}
.bg .notelink:active {
  opacity: 80%;
  box-shadow: inset 0 -0.095rem 0 0 rgba(var(--accent), 0%), inset 5rem -1rem 1rem 0 rgba(var(--accent), 15%), inset 10rem -1.5rem 1rem 0 rgba(var(--accent), 15%), inset 15rem -2rem 1rem 0 rgba(var(--accent), 15%), inset 20rem -2.5rem 1rem 0 rgba(var(--accent), 15%), 0 0 0.15rem 0 rgba(var(--accent), 0%);
}
.bg .badge {
  font-size: 1rem;
  font-family: "jetbrains mono";
  line-height: 100%;
  font-weight: 400;
  color: rgba(var(--bg), 100%);
  max-width: max-content;
  max-height: max-content;
  margin: 0rem 0rem 0rem -0.5rem;
  padding: 7px 16px 6px;
  background-color: rgba(var(--text), 100%);
  border: 1px solid rgba(var(--text), 100%);
  border-radius: 0.875rem;
  box-shadow: 1px 1px 0px rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, false, false;
}
@-moz-document url-prefix() {
  .bg .badge {
    padding: 6px 16px;
  }
}
.bg .badge-outline {
  font-size: 1rem;
  font-family: "jetbrains mono";
  line-height: 100%;
  font-weight: 400;
  color: rgba(var(--bg), 100%);
  max-width: max-content;
  max-height: max-content;
  margin: 0rem 0rem 0rem -0.5rem;
  padding: 7px 16px 6px;
  background-color: rgba(var(--text), 100%);
  border: 1px solid rgba(var(--text), 100%);
  border-radius: 0.875rem;
  box-shadow: 1px 1px 0px rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, false, false;
  background-color: rgba(var(--bg), 100%);
  color: rgba(var(--text), 100%);
  border: 1px solid rgba(var(--accent), 75%);
  box-shadow: 1px 1px 0px rgba(var(--accent), 75%);
}
@-moz-document url-prefix() {
  .bg .badge-outline {
    padding: 6px 16px;
  }
}
.bg .button {
  font-family: "jetbrains mono";
  font-weight: 500;
  font-size: clamp(1rem, 2.2vw, 1.125rem);
  padding: clamp(0.375rem, 1px + 2vw, 0.625rem) 1.375rem clamp(0.5rem, 1px + 2vw, 0.75rem);
  margin-left: -0.3125rem;
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  border-color: rgba(var(--text), 100%);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 0px rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, margin 200ms ease-in-out, padding 200ms ease-in-out, false, false, false, translate 200ms ease-in-out, false, box-shadow 200ms ease-in-out;
}
.bg .button:hover {
  translate: -3px -3px;
  color: rgba(var(--blk), 100%);
  background-color: rgba(var(--accent), 100%);
  border-color: rgba(var(--accent), 0%);
  box-shadow: 4px 4px 0px rgba(var(--blk), 100%);
}
.bg .button:active {
  translate: -2px -2px;
  filter: brightness(0.9) saturate(1.25);
}
.bg .button-outline, .bg .sidebar-item {
  font-family: "jetbrains mono";
  font-weight: 500;
  font-size: clamp(1rem, 2.2vw, 1.125rem);
  padding: clamp(0.375rem, 1px + 2vw, 0.625rem) 1.375rem clamp(0.5rem, 1px + 2vw, 0.75rem);
  margin-left: -0.3125rem;
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  border-color: rgba(var(--text), 100%);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 0px rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, margin 200ms ease-in-out, padding 200ms ease-in-out, false, false, false, translate 200ms ease-in-out, false, box-shadow 200ms ease-in-out;
  color: rgba(var(--text), 100%);
  background-color: rgba(var(--accent), 20%);
  border-color: rgba(var(--accent), 100%);
}
.bg .button-outline:hover, .bg .sidebar-item:hover {
  translate: -3px -3px;
  color: rgba(var(--blk), 100%);
  background-color: rgba(var(--accent), 100%);
  border-color: rgba(var(--accent), 0%);
  box-shadow: 4px 4px 0px rgba(var(--blk), 100%);
}
.bg .button-outline:active, .bg .sidebar-item:active {
  translate: -2px -2px;
  filter: brightness(0.9) saturate(1.25);
}
.bg .quote {
  place-content: center start;
  gap: 1rem;
  padding: var(--card-padding);
  background: rgba(var(--accent), 15%);
  border-left: 3px solid rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, margin 200ms ease-in-out, padding 200ms ease-in-out, height 200ms ease-in, min-height 200ms ease-in, max-height 200ms ease-in, width 200ms ease-in, min-width 200ms ease-in, max-width 200ms ease-in, false, false, false, false;
}
.bg .outline {
  place-self: center;
  place-content: center;
  padding: var(--card-padding);
  border-radius: 0.875rem;
  background-color: rgba(var(--bg), 100%);
  border: 2px solid rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, margin 200ms ease-in-out, padding 200ms ease-in-out, height 200ms ease-in, min-height 200ms ease-in, max-height 200ms ease-in, width 200ms ease-in, min-width 200ms ease-in, max-width 200ms ease-in, false, false, false, false;
}
.bg .invert {
  place-self: center;
  place-content: center;
  padding: var(--card-padding);
  border-radius: 0.875rem;
  background-color: rgba(var(--bg), 100%);
  border: 2px solid rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, margin 200ms ease-in-out, padding 200ms ease-in-out, height 200ms ease-in, min-height 200ms ease-in, max-height 200ms ease-in, width 200ms ease-in, min-width 200ms ease-in, max-width 200ms ease-in, false, false, false, false;
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  border: 2px solid rgba(var(--text), 100%);
}
.bg .invert .badge {
  font-size: 1rem;
  font-family: "jetbrains mono";
  line-height: 100%;
  font-weight: 400;
  color: rgba(var(--bg), 100%);
  max-width: max-content;
  max-height: max-content;
  margin: 0rem 0rem 0rem -0.5rem;
  padding: 7px 16px 6px;
  background-color: rgba(var(--text), 100%);
  border: 1px solid rgba(var(--text), 100%);
  border-radius: 0.875rem;
  box-shadow: 1px 1px 0px rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, false, false;
  background-color: rgba(var(--bg), 100%);
  color: rgba(var(--text), 100%);
  border: 1px solid rgba(var(--bg), 100%);
  box-shadow: 1px 1px 0px rgba(var(--accent), 100%);
}
@-moz-document url-prefix() {
  .bg .invert .badge {
    padding: 6px 16px;
  }
}
.bg .invert .badge-outline {
  font-size: 1rem;
  font-family: "jetbrains mono";
  line-height: 100%;
  font-weight: 400;
  color: rgba(var(--bg), 100%);
  max-width: max-content;
  max-height: max-content;
  margin: 0rem 0rem 0rem -0.5rem;
  padding: 7px 16px 6px;
  background-color: rgba(var(--text), 100%);
  border: 1px solid rgba(var(--text), 100%);
  border-radius: 0.875rem;
  box-shadow: 1px 1px 0px rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, false, false, false, false, false, false;
  background-color: rgba(var(--text), 100%);
  color: rgba(var(--bg), 100%);
  border: 1px solid rgba(var(--accent), 75%);
  box-shadow: 1px 1px 0px rgba(var(--accent), 75%);
}
@-moz-document url-prefix() {
  .bg .invert .badge-outline {
    padding: 6px 16px;
  }
}
.bg .invert .button {
  font-family: "jetbrains mono";
  font-weight: 500;
  font-size: clamp(1rem, 2.2vw, 1.125rem);
  padding: clamp(0.375rem, 1px + 2vw, 0.625rem) 1.375rem clamp(0.5rem, 1px + 2vw, 0.75rem);
  margin-left: -0.3125rem;
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  border-color: rgba(var(--text), 100%);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 0px rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, margin 200ms ease-in-out, padding 200ms ease-in-out, false, false, false, translate 200ms ease-in-out, false, box-shadow 200ms ease-in-out;
  color: rgba(var(--text), 100%);
  background-color: rgba(var(--bg), 100%);
  border-color: rgba(var(--bg), 100%);
}
.bg .invert .button:hover {
  translate: -3px -3px;
  color: rgba(var(--blk), 100%);
  background-color: rgba(var(--accent), 100%);
  border-color: rgba(var(--accent), 0%);
  box-shadow: 4px 4px 0px rgba(var(--blk), 100%);
}
.bg .invert .button:active {
  translate: -2px -2px;
  filter: brightness(0.9) saturate(1.25);
}
.bg .invert .button-outline, .bg .invert .sidebar-item {
  font-family: "jetbrains mono";
  font-weight: 500;
  font-size: clamp(1rem, 2.2vw, 1.125rem);
  padding: clamp(0.375rem, 1px + 2vw, 0.625rem) 1.375rem clamp(0.5rem, 1px + 2vw, 0.75rem);
  margin-left: -0.3125rem;
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  border-color: rgba(var(--text), 100%);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  box-shadow: 4px 4px 0px rgba(var(--accent), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, margin 200ms ease-in-out, padding 200ms ease-in-out, false, false, false, translate 200ms ease-in-out, false, box-shadow 200ms ease-in-out;
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--accent), 20%);
  border-color: rgba(var(--accent), 100%);
}
.bg .invert .button-outline:hover, .bg .invert .sidebar-item:hover {
  translate: -3px -3px;
  color: rgba(var(--blk), 100%);
  background-color: rgba(var(--accent), 100%);
  border-color: rgba(var(--accent), 0%);
  box-shadow: 4px 4px 0px rgba(var(--blk), 100%);
}
.bg .invert .button-outline:active, .bg .invert .sidebar-item:active {
  translate: -2px -2px;
  filter: brightness(0.9) saturate(1.25);
}
.bg .project-card {
  position: relative;
  display: grid;
  grid-template-rows: 0.25fr 1fr 0.5fr;
  row-gap: clamp(1rem, 0.7403560831rem + 1.1869436202vw, 1.5rem);
  padding: 1.5rem 1.75rem 1.625rem;
  border-radius: 0.5rem;
  border: 2px solid rgba(var(--blk), 100%);
  color: rgba(var(--text), 100%);
  background-color: rgba(var(--accent), 35%);
  box-shadow: 4px 4px 0px rgba(var(--blk), 100%);
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, margin 200ms ease-in-out, padding 200ms ease-in-out, false, false, scale 200ms ease-in-out, translate 200ms ease-in-out, false, filter 200ms ease-in-out;
}
@media (min-width: 768px) {
  .bg .project-card {
    padding: 2.5rem 3rem 2.5rem;
    border-radius: 0.875rem;
  }
}
.bg .project-card > .badge,
.bg .project-card .badge-outline {
  color: rgba(var(--text), 85%);
  background-color: rgba(var(--text), 0%);
  border: 1px solid rgba(var(--blk), 65%);
  box-shadow: 1px 1px 0px rgba(var(--blk), 65%);
}
.bg .project-card:hover {
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  translate: -3px -3px !important;
}
.bg .project-card:hover > h2 {
  filter: none;
}
.bg .project-card:hover > .badge,
.bg .project-card:hover .badge-outline {
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--bg), 25%);
  border: 1px solid rgba(var(--blk), 100%);
  box-shadow: 1px 1px 0px rgba(var(--blk), 100%);
}
.bg .project-card .title {
  align-self: center;
}
.bg .project-card .caption {
  align-self: center;
}
.bg .sidebar {
  z-index: 5000;
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 0px;
  top: 0;
  bottom: 0rem;
  height: 100%;
  width: 100%;
  translate: calc(-100vw - 8px) 0;
}
@media (min-width: 512px) {
  .bg .sidebar {
    grid-template-columns: 1fr 75px;
    width: 22rem;
    padding: 0rem;
    translate: -22rem 0;
  }
}
.bg .sidebar > * {
  color: rgba(var(--bg), 100%);
}
.bg .sidebar-content {
  position: relative;
  display: grid;
  grid-template-rows: 4rem auto;
  align-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
  background-color: rgba(var(--text), 100%);
  box-shadow: 8px 0px rgba(var(--accent), 100%);
}
@media (min-width: 512px) {
  .bg .sidebar-content {
    height: 100dvh;
    width: 22rem;
    padding: 3rem 3rem 3rem 1.5rem;
  }
}
.bg .sidebar-content > * {
  color: rgba(var(--bg), 100%);
}
.bg .sidebar-toggle-container {
  pointer-events: none;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  place-content: end;
  top: 0rem;
  bottom: 0rem;
  height: 100dvh;
  width: calc(100vw + 4.875rem);
}
@media (min-width: 512px) {
  .bg .sidebar-toggle-container {
    right: initial;
    padding: 0;
    width: 100%;
    align-content: start;
  }
}
.bg .sidebar-toggle {
  z-index: 5001;
  position: absolute;
  pointer-events: auto;
  place-self: start end;
  font-size: 1rem;
  font-family: "jetbrains mono";
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
  height: 54px;
  padding: 0rem 1rem;
  border: none;
  border-radius: 0.325rem;
  transition: color 50ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, margin 200ms ease-in-out, padding 200ms ease-in-out, false, false, false, false, false;
}
.bg .sidebar-toggle:hover {
  color: rgba(var(--bg), 100%);
  background-color: rgba(var(--text), 100%);
}
.bg .sidebar-item {
  font-size: 1rem;
  border-radius: 0.325rem;
  border: 2px solid rgba(var(--accent), 100%);
  margin: 0;
}
.bg .sidebar-item.active {
  color: rgba(var(--text), 100%);
  background-color: rgba(var(--bg), 100%);
  border-color: rgba(var(--bg), 100%);
  box-shadow: 4px 4px 0px rgba(var(--blk), 100%);
}

* {
  cursor: url("/public/cursor/cursor_idle.png") -64 -64, auto;
}

*:active {
  cursor: url("/public/cursor/cursor_glowing.png") 10 10, auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba(var(--accent), 100%);
  color: rgba(var(--accent), 100%);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(var(--blk), 100%);
  color: rgba(var(--blk), 100%);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(var(--blk), 100%);
  color: rgba(var(--blk), 100%);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar:window-inactive {
  visibility: hidden;
}

