@use 'sass:color';
@use 'sass:list';
@use 'sass:map';
@use 'sass:math';
@use 'sass:meta';
@use 'sass:selector';
@use 'sass:string';

//
// import
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@import './reset.css';
@import './a-config/mixins';
@import './a-config/variables';
@import './a-config/functions';
@import './a-config/colors';
@import './a-config/layout-containers';
@import './a-config/utilities';
@import './text/text-styles';
@import './text/link-styles';
//@import "./effects/animations";
//@import "./effects/shadows";
//@import "./objects";

@import './component-styles/button';
@import './component-styles/color-picker';
@import './component-styles/work_projects';
@import './component-styles/nav';
@import './component-styles/sidebar';
@import './component-styles/mockup-devices';
@import './component-styles/contact-form';
@import './component-styles/_slide-switcher.scss';
@import './component-styles/_computer-avatar.scss';

@import './a-config/color-assembler';
//
// cursor
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
* {
  cursor:
    url('/public/cursor/cursor_idle.png') -64 -64,
    auto;
}

*:active {
  cursor:
    url('/public/cursor/cursor_glowing.png') 10 10,
    auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgb-var(accent);
  color: rgb-var(accent);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgb-var(blk);
  color: rgb-var(blk);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgb-var(blk);
  color: rgb-var(blk);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar:window-inactive {
  visibility: hidden;
}
