// nav menu
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.color-set-toggles-bar.color-set-nav {
  display: grid;
  gap: 0.25rem;
  height: 100%;
}

.color-set-nav > .color-set-toggle {
  aspect-ratio: 1 / 1;
  width: 100%;
  max-width: 55px;

  padding: 1.125rem;
  border-radius: 2rem;
  border: 8px solid rgb-var(blk);

  opacity: 0;
  scale: 0.8;
  transform: translateY(100px);

  transition: transition($color: true, $scale: true);

  &:hover {
    scale: 0.9 !important;
    filter: saturate(1.25);
  }
}

// home page
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.color-set-toggles-bar.color-set-home {
  display: flex;
  flex-flow: row wrap;
  place-items: center;
  place-content: center;
  gap: 0.375rem;
  width: 100%;

  @include breakpoint(xs) {
    width: 260px;
  }
  @include breakpoint(sm) {
    width: 100%;
  }
}

.color-set-home > .color-set-toggle {
  aspect-ratio: 1 / 1;
  flex: 1 1 calc(100% / 7);
  max-width: 55px;

  padding: 1.125rem;
  border-radius: 2rem;
  border: 8px solid rgb-var(bg);

  transition: transition($color: true, $scale: true);

  &:hover {
    scale: 0.9 !important;
    filter: saturate(1.25);
  }
}
