//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//
// DEVICES CONTAINER
//
//
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// device containers
//-----------------------------------------------------------
.devices-container {
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  place-items: center;

  overflow-x: clip;

  width: 100%;

  @include breakpoint(md) {
    overflow-x: visible;
  }
}

.device-container {
  display: flex;
  place-content: center;
  grid-column: 1;
  grid-row: 1;

  padding: 0rem;
  width: 100%;

  @include breakpoint(lg) {
    padding: 0rem;
  }
}

// device toggle buttons
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.device-toggle-button {
  font-size: 1rem !important;
  margin: 0 !important;
  padding: 0.5rem 1rem !important;
  box-shadow: none !important;

  &.right {
    border-radius: 0rem $border-rad $border-rad 0rem !important;
  }
  &.left {
    border-radius: $border-rad 0rem 0rem $border-rad !important;
  }

  &:hover {
    translate: 0 !important;
  }

  &:active {
    translate: 0 !important;
  }
}

// device toggler
//-----------------------------------------------------------
.device-page-toggle {
  font-size: 1rem !important;
  padding: 0.4375rem 1rem 0.5rem !important;
  margin: 0rem !important;
  border-radius: $border-rad-sm !important;
  box-shadow: none !important;

  &:hover {
    translate: $hover-translate-sm !important;
  }
}

.page-toggles-container {
  gap: 0.5rem;
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//
// DEVICES
//
//
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// DEVICE BASE MIXIN
//-----------------------------------------------------------
@mixin device-base($max-width, $min-width) {
  z-index: 100;
  display: flex;
  place-items: center;
  place-content: center;
  position: relative;
  width: 100%;
  max-width: $max-width;
  min-width: $min-width;
}

// DEVICE IMG MASK MIXIN
//--------------------------------------------------------------------
@mixin device-frame() {
  z-index: 2;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

// PALM PRE
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.palm-pre {
  @include device-base(400px, 250px);
  scale: 0;
}

.palm-pre-top {
  z-index: 3;
  pointer-events: none;
}

.palm-pre-img-mask {
  @include device-frame();
  aspect-ratio: 638/901;
  scale: 1.055;
  translate: 1% -1.5%;
}

.palm-pre-img {
  width: 100%;
}

.palm-pre-bottom {
  z-index: 1;
  position: absolute;
}

// CRT MONITOR
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

.crt-monitor {
  @include device-base(1000px, 300px);
}

.crt-monitor-frame {
  z-index: 3;
  pointer-events: none;
}

.crt-monitor-img-mask {
  @include device-frame();
  aspect-ratio: 341/256;
  scale: 0.6075;
  translate: -0.05% -12.15%;
}

.crt-monitor-img {
  width: 100%;
}
