.computer-home {
    position: absolute;
    top: calc(8dvh - 10px);
    right: calc(36dvw - 100px);
    width: 200px;
    transition: scale 650ms ease-in-out, opacity 650ms ease-in-out;
    scale: 1;
    opacity: .65;

    &:hover {
        opacity: 1;
        scale: 1.125 !important;
    }
}