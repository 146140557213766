// color variables are in _colors.scss

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//
// layout
//
//
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

$max-columns: 18;

$object-gap-min: 32;
$object-row-gap-max: 54;
$object-col-gap-max: 80;

$item-gap-min: 24;
$item-gap-max: 28;

$container-padding-x-min: 16;
$container-padding-x-max: 48;
$container-padding-y-min: 54;
$container-padding-y-max: 96;

@include cssvar-set(
  (
    max-columns: #{$max-columns},
    current-columns: '',
    spacer: 1rem,
    card-padding: clamp(2rem, calc(1.125 * 5vw), 1.5rem)
      clamp(1rem, calc(1.1 * 5vw), 2rem) clamp(2.125rem, calc(1.1 * 5vw), 1.625rem)
  )
);

$page-breakpoints: (
  xxs: 0,
  xs: 350,
  sm: 512,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536
);

$place-start: flex-start !default;
$place-center: center !default;

$spacer: var(--spacer) !default;

$border-rad-sm: 0.325rem !default;
$border-rad: 0.5rem !default;
$border-rad-lg: 0.875rem !default;

$border-width-sm: 1px !default;
$border-width: 2px !default;
$border-width-lg: 3px !default;

$timing-fast: 50ms !default;
$timing: 200ms !default;

$button-shadow: 4px 4px 0px !default;
$badge-shadow: 1px 1px 0px !default;

$hover-translate-sm: -2px -2px !default;
$hover-translate: -3px -3px !default;

// spacers
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

$spacer-types: (
  'padding': 'p',
  'margin': 'm'
);

$spacer-areas: (
  '': '',
  '-top': 't',
  '-right': 'r',
  '-bottom': 'b',
  '-left': 'l'
);

// placement
//-----------------------------------------------------------
$place-content: (
  'start': 'flex-start',
  'end': 'flex-end',
  'center': 'center',
  'between': 'space-between',
  'around': 'space-around',
  'evenly': 'space-evenly',
  'stretch': 'stretch'
);

$place-items: (
  'start': 'flex-start',
  'end': 'flex-end',
  'self-start': 'self-start',
  'self-end': 'self-end',
  'center': 'center',
  'baseline': 'baseline',
  'stretch': 'stretch'
);
