//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//
// AUTO backgrounds
//
//
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// use bg mixin to remove this page and have color assembly happen locally in each file
//-----------------------------------------------------------
@mixin bg() {
  color: rgb-var(text);
  background-color: rgb-var(bg);
  transition: transition($color: true);
  @content;
}

// build bgs
.bg {
  @include bg() {
    .plink {
      @extend p;
      @include p-link();
    }
    .notelink {
      @extend .note;
      opacity: 1 !important;
      @include p-link();
    }

    // badges
    //-----------------------------------------------------------
    .badge {
      @include badge-base();
      &-outline {
        @include badge-base($outline: true);
      }
    }

    // buttons
    //-----------------------------------------------------------
    .button {
      @include buttons();

      &-outline {
        @include buttons($outline: true);
      }
    }

    // block quote
    //-----------------------------------------------------------
    .quote {
      @include block-quote();
    }

    // outline/invert cols
    //------------------------------------------------------------------------------------
    //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    .outline {
      @include column-outline();
    }

    .invert {
      @include column-outline($invert: true);
      .badge {
        @include badge-base($invert: true);
        &-outline {
          @include badge-base($outline-invert: true);
        }
      }

      // buttons
      //-----------------------------------------------------------
      .button {
        @include buttons($invert: true);
        &-outline {
          @include buttons($outline-invert: true);
        }
      }
    }

    // work page project cards
    //-----------------------------------------------------------
    @include project-card();

    // sidebar
    //-----------------------------------------------------------
    @include sidebar();
  }
}
