$nav-transitions: transition(
  $color: true,
  $translate: true
);

// containers
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.nav-placement-wrap {
  pointer-events: none;
  z-index: 9999;
  position: fixed;
  bottom: 0rem;
  right: 0rem;

  display: flex;
  place-content: end;

  width: 100%;
  max-width: 100dvw;
  max-height: 100dvh;
  padding: 0.5rem;

  transition: $nav-transitions;

  @include breakpoint(md) {
    padding: 1rem;
  }
}

.menu-container {
  pointer-events: auto;
  z-index: 9999;
  display: grid;
  grid-template-columns: 55px 1fr;
  gap: 1rem;

  width: 155px;
  max-width: 550px;
  height: 100%;
  max-height: 550px;
  padding: 0.5rem;

  border-radius: $border-rad;
  background-color: rgb-var(blk);

  transition: $nav-transitions;
}

// colors
//-----------------------------------------------------------
.colors-mask {
  overflow: hidden;
  position: relative;
  height: 0px;
  padding-bottom: 0rem;
}

.colors-container {
  pointer-events: auto;
  display: grid;
  grid-template-rows: 0fr 54px;
  justify-items: stretch;
  justify-content: stretch;

  width: 100%;
  height: 100%;

  border-radius: $border-rad;
}

// nav
//-----------------------------------------------------------
.nav-mask {
  overflow: hidden;
  position: relative;
  height: 0px;
  padding-bottom: 0rem;
}

.nav-container {
  pointer-events: auto;
  display: grid;
  grid-template-rows: 0fr 54px;

  width: 100%;
  height: 100%;

  border-radius: $border-rad;
}

.nav-link-container {
  pointer-events: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  width: 100%;
  height: 100%;
  padding: 1rem;

  border-radius: $border-rad;
  background-color: rgb-var(bg);

  transition: $nav-transitions;
  transform-origin: bottom center;

  @include breakpoint(sm) {
    grid-template-columns: 1fr 1fr;
  }
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
// TOGGLES
//
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@mixin toggle-base() {
  font-family: $jet-brains;
  font-size: 1rem;
  color: rgb-var(bg);
  background-color: rgb-var(text);
  padding: 0.625rem 1rem 0.75rem;
  border-radius: $border-rad-sm;
  border: $border-width solid rgb-var(text);

  transition: $nav-transitions;

  &:hover {
    color: rgb-var(text);
    background-color: rgb-var(bg);
    border: $border-width solid rgb-var(accent);
    box-shadow: $button-shadow 0px rgb-var(accent);
    translate: -4px -4px;
  }
}

// toggle theme
//-----------------------------------------------------------
.theme-toggle {
  @include toggle-base();
  margin-left: 0rem !important;
}

.nav-toggle {
  @include toggle-base();
  justify-self: end;
  z-index: 10;
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
// LINKS
//
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// link buttons
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.nav-link {
  display: flex;
  place-items: center;
  place-content: center;

  font-family: $jet-brains;
  font-size: 1.125rem;
  text-align: center;

  width: 100%;
  height: 100%;
  padding: 2rem;

  border-radius: $border-rad-sm;
  border: $border-width solid rgb-var(blk);

  opacity: 1;
  color: rgb-var(text);
  background-color: rgb-var(accent, 15);
  box-shadow: $button-shadow rgb-var(blk);

  scale: 0.8;
  translate: 0;

  transition: $nav-transitions;

  &:hover {
    color: rgb-var(text);
    background-color: rgb-var(accent, 50);
    translate: -2px -2px;
  }
  &.active {
    color: rgb-var(bg);
    background-color: rgb-var(text);
    border: $border-width solid rgb-var(blk);
    &:hover {
      color: rgb-var(bg);
      background-color: rgb-var(accent);
      border: $border-width solid rgb-var(blk);
    }
  }
}
