a {
  color: inherit;
  text-decoration: none;
}

// P LINK STYLE MIXIN
//-----------------------------------------------------------
@mixin p-link($invert: false) {
  white-space: nowrap;
  letter-spacing: -0.0625rem;
  font-weight: $weight-light;
  text-decoration: none;
  text-shadow: 0rem 0.0625rem rgb-var(blk, 0);

  padding: 0rem 0.125rem 0rem 0.125rem;
  margin: 0rem -0.125rem 0rem -0.125rem;

  border-radius: 0;
  filter: saturate(1.5);

  box-shadow:
    // underline
    inset 0 -#{$border-width} 0 0 rgb-var(accent, 60),
    // left shading
    inset 0rem 0rem 0 0 rgb-var(accent, 0),
    inset 0rem 0rem 0 0 rgb-var(accent, 0), inset 0rem 0rem 0 0 rgb-var(accent, 0),
    inset 0rem 0rem 0 0 rgb-var(accent, 0),
    // drop shadow
    0 0 1rem 0 rgb-var(accent, 0);

  transition: all $timing ease-out;

  // link hover
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  &:hover {
    color: rgb-var(blk);
    //text-shadow: 0rem 0.0625rem rgb-var(blk, 70);
    padding: 0rem 0.375rem 0rem 0.375rem;
    margin-left: -0.375rem;
    margin-right: -0.375rem;

    border-radius: $border-rad-sm;

    box-shadow:
      // underline
      inset 0 -0.095rem 0 0 rgb-var(accent, 0),
      // left shading
      inset 5rem -1rem 1rem 0 rgb-var(accent, 5),
      inset 10rem -1.5rem 1rem 0 rgb-var(accent, 15),
      inset 15rem -2rem 1rem 0 rgb-var(accent, 25),
      inset 20rem -2.5rem 1rem 0 rgb-var(accent, 35),
      // drop shadow
      0 0 0.15rem 0 rgb-var(accent, 0);
  }

  // link active
  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  &:active {
    opacity: 80%;
    box-shadow:
      // underline
      inset 0 -0.095rem 0 0 rgb-var(accent, 0),
      // left shading
      inset 5rem -1rem 1rem 0 rgb-var(accent, 15),
      inset 10rem -1.5rem 1rem 0 rgb-var(accent, 15),
      inset 15rem -2rem 1rem 0 rgb-var(accent, 15),
      inset 20rem -2.5rem 1rem 0 rgb-var(accent, 15),
      // drop shadow
      0 0 0.15rem 0 rgb-var(accent, 0);
  }
}
