$sidebar-width-sm: 22rem; //clamp(16rem, calc(12 * 2vw), 20rem);

@mixin sidebar() {
  // sidebar
  //-----------------------------------------------------------
  .sidebar {
    z-index: 5000;
    position: fixed;
    display: grid;
    grid-template-columns: 1fr 0px;

    top: 0;
    bottom: 0rem;
    height: 100%;
    width: 100%;

    translate: calc(-100vw - 8px) 0;

    @include breakpoint(sm) {
      grid-template-columns: 1fr 75px;
      width: $sidebar-width-sm;
      padding: 0rem;

      translate: #{'-' + $sidebar-width-sm} 0;
    }
    > * {
      color: rgb-var(bg);
    }
  }
  .sidebar-content {
    position: relative;
    display: grid;
    grid-template-rows: 4rem auto;

    align-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
    padding: 1rem;

    background-color: rgb-var(text);
    box-shadow: 8px 0px rgb-var(accent);

    @include breakpoint(sm) {
      height: 100dvh;
      width: $sidebar-width-sm;
      padding: 3rem 3rem 3rem 1.5rem;
    }
    > * {
      color: rgb-var(bg);
    }
  }

  // sidebar toggle
  //-----------------------------------------------------------
  .sidebar-toggle-container {
    pointer-events: none;
    position: relative;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    place-content: end;
    top: 0rem;
    bottom: 0rem;

    height: 100dvh;
    width: calc(100vw + 4.875rem); // controls offset from sidebar

    @include breakpoint(sm) {
      right: initial;
      padding: 0;
      width: 100%;
      align-content: start;
    }
  }

  .sidebar-toggle {
    z-index: 5001;
    position: absolute;
    pointer-events: auto;
    place-self: start end;

    font-size: 1rem;
    font-family: $jet-brains;

    color: rgb-var(bg);
    background-color: rgb-var(text);

    height: 54px;
    padding: 0rem 1rem;
    border: none;
    border-radius: $border-rad-sm;

    transition: transition($color: true, $spacer: true);

    &:hover {
      color: rgb-var(bg);
      background-color: rgb-var(text);
    }
    &.active {
      //box-shadow: $button-shadow #990d00;
    }
  }

  // sidebar links
  //-----------------------------------------------------------
  .sidebar-item {
    @extend .button-outline;
    font-size: 1rem;
    border-radius: $border-rad-sm;
    border: $border-width solid rgb-var(accent);
    margin: 0;

    &.active {
      color: rgb-var(text);
      background-color: rgb-var(bg);
      border-color: rgb-var(bg);
      box-shadow: $button-shadow rgb-var(blk);
    }
  }
}
