//
// BUTTONS
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@mixin buttons($outline: false, $outline-invert: false, $invert: false) {
  font-family: $jet-brains;
  font-weight: $weight-medium;
  font-size: clamp(1rem, calc(1.1 * 2vw), 1.125rem);

  padding: clamp(0.375rem, calc(1px + 2vw), 0.625rem) 1.375rem
    clamp(0.5rem, calc(1px + 2vw), 0.75rem);
  margin-left: -0.3125rem;

  color: rgb-var(bg);
  background-color: rgb-var(text);
  border-color: rgb-var(text);
  border-style: solid;
  border-width: $border-width-sm;
  border-radius: $border-rad;
  box-shadow: $button-shadow rgb-var(accent);

  transition: transition($color: true, $translate: true, $spacer: true),
    box-shadow $timing ease-in-out;

  @if $outline {
    color: rgb-var(text);
    background-color: rgb-var(accent, 20);
    border-color: rgb-var(accent);
  }

  @if $invert {
    color: rgb-var(text);
    background-color: rgb-var(bg);
    border-color: rgb-var(bg);
  }

  @if $outline-invert {
    color: rgb-var(bg);
    background-color: rgb-var(accent, 20);
    border-color: rgb-var(accent);
  }

  &:hover {
    translate: $hover-translate;
    color: rgb-var(blk, 100);
    background-color: rgb-var(accent, 100);
    border-color: rgb-var(accent, 0);
    box-shadow: $button-shadow rgb-var(blk);
  }

  &:active {
    translate: -2px -2px;
    filter: brightness(0.9) saturate(1.25);
  }
}
