//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//
// PROJECTS PAGE
//
//
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// back to work button
//-----------------------------------------------------------
.projects-return {
  z-index: 9000;
  position: fixed;
  font-size: clamp(1.25rem, calc(1.125 * 4vw), 1.5rem) !important;
  top: 0.5rem;
  left: 0.75rem;

  padding: 0.65rem 1.3rem 0.75rem 1.25rem !important;

  @include breakpoint(md) {
    top: 1rem;
    left: 1rem;
  }
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 450px));
  grid-template-rows: repeat(auto-fit, auto);
  gap: clamp-scale($object-gap-min, $object-row-gap-max);
  place-content: $place-center;

  min-height: 100vh;
  padding: clamp-scale($container-padding-y-min, $container-padding-y-max)
    clamp-scale($container-padding-x-min, $container-padding-x-max);
}

// page image background
//-----------------------------------------------------------
.project-cover-bg {
  pointer-events: none;
  z-index: -100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  opacity: 0;
  background-size: cover;

  transition: opacity $timing ease-in-out;
}

// project card
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
@mixin project-card() {
  .project-card {
    position: relative;

    display: grid;
    grid-template-rows: 0.25fr 1fr 0.5fr;
    row-gap: clamp-scale(16, 24);

    padding: 1.5rem 1.75rem 1.625rem;
    border-radius: $border-rad;
    border: $border-width solid rgb-var(blk);
    color: rgb-var(text, 100);
    background-color: rgb-var(accent, 35);
    box-shadow: $button-shadow rgb-var(blk);

    transition:
      transition($color: true, $spacer: true, $scale: true, $translate: true),
      filter $timing ease-in-out;

    @include breakpoint(md) {
      padding: 2.5rem 3rem 2.5rem;
      border-radius: $border-rad-lg;
    }

    > .badge,
    .badge-outline {
      color: rgb-var(text, 85);
      background-color: rgb-var(text, 0);
      border: $border-width-sm solid rgb-var(blk, 65);
      box-shadow: $badge-shadow rgb-var(blk, 65);
    }

    &:hover {
      color: rgb-var(bg);
      background-color: rgb-var(text);
      translate: $hover-translate !important;
      > h2 {
        filter: none;
      }
      > .badge,
      .badge-outline {
        color: rgb-var(bg);
        background-color: rgb-var(bg, 25);
        border: $border-width-sm solid rgb-var(blk);
        box-shadow: $badge-shadow rgb-var(blk);
      }
    }

    .title {
      align-self: center;
    }

    .caption {
      align-self: center;
    }
  }
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
//
// PROJECT PAGES
//
//
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// image gallery
//------------------------------------------------------------------------------------
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
.gallery {
  place-content: space-between;
  place-items: stretch;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  @include breakpoint(md) {
    grid-template-columns: [left-button] 40px [slide] 1fr [right-button] 40px;
    grid-template-areas: none;
    gap: 0;
    place-items: center;
    margin-left: 0;
    margin-right: 0;
  }
}

.gallery-button {
  z-index: 8000;
  font-size: 1.5rem !important;
  padding: 0.125rem 1.5rem 0.25rem !important;
  border-radius: $border-rad !important;

  &:hover {
    background-color: rgb-var(accent) !important;
  }
}

.slide {
  display: none;
  grid-column: slide;
  grid-row: 1;

  width: 100%;

  &.active {
    display: block;
  }
  > img {
    height: 100%;
    width: 100%;
    max-height: 80vh;
  }
}
